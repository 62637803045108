import React, { createContext, useContext, useState } from 'react'

interface ResetContextType {
  resetCount: number
  resetApp: () => void
}

const ResetContext = createContext<ResetContextType | undefined>(undefined)

interface ResetProviderProps {
  children: React.ReactNode
}

export const ResetProvider: React.FC<ResetProviderProps> = ({ children }) => {
  const [resetCount, setResetCount] = useState(0)

  const resetApp = () => {
    // Increment the reset count, which will trigger components to reset
    setResetCount((prev) => prev + 1)
  }

  return (
    <ResetContext.Provider value={{ resetCount, resetApp }}>
      {children}
    </ResetContext.Provider>
  )
}

export const useReset = (): ResetContextType => {
  const context = useContext(ResetContext)
  if (context === undefined) {
    throw new Error('useReset must be used within a ResetProvider')
  }
  return context
}
