import React, { useState, useEffect, useRef } from 'react'

interface TypingTitleProps {
  phrases?: string[]
  typingSpeed?: number
  deleteSpeed?: number
  delayTime?: number
}

// Enhanced typing animation
const TypingTitle: React.FC<TypingTitleProps> = ({
  phrases = [
    'Digital Experiences',
    'Scalable Solutions',
    'Cloud Architecture',
    'Modern Interfaces'
  ],
  typingSpeed = 100,
  deleteSpeed = 50,
  delayTime = 2000
}) => {
  const [phraseIndex, setPhraseIndex] = useState(0)
  const [charIndex, setCharIndex] = useState(0)
  const [isDeleting, setIsDeleting] = useState(false)
  const [text, setText] = useState('')
  const [delta, setDelta] = useState(typingSpeed)
  const [isCursorBlinking, setIsCursorBlinking] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null)

  // Find the longest phrase to set container width
  const longestPhrase = phrases.reduce(
    (longest, current) => (current.length > longest.length ? current : longest),
    ''
  )

  // Text gradient effect
  const gradientText =
    'bg-clip-text text-transparent bg-gradient-to-r from-cyan-300 to-blue-500'

  useEffect(() => {
    const tick = () => {
      const currentPhrase = phrases[phraseIndex]

      // If deleting, remove a character, otherwise add a character
      const updatedText = isDeleting
        ? currentPhrase.substring(0, charIndex - 1)
        : currentPhrase.substring(0, charIndex + 1)

      setText(updatedText)

      // Update indices
      if (isDeleting) {
        setCharIndex((prev) => prev - 1)
      } else {
        setCharIndex((prev) => prev + 1)
      }

      // Control typing speed
      if (isDeleting) {
        setDelta(deleteSpeed) // Delete faster with consistent speed
      } else {
        // Vary typing speed slightly for natural effect
        setDelta(typingSpeed - Math.random() * 30)
      }

      // Control cursor blinking
      if (isDeleting && charIndex === 1) {
        setIsCursorBlinking(true)
      } else if (!isDeleting && charIndex === currentPhrase.length) {
        setIsCursorBlinking(true)
      } else {
        setIsCursorBlinking(false)
      }

      // Switch between typing and deleting
      if (!isDeleting && charIndex === currentPhrase.length) {
        // Pause at end of phrase
        setTimeout(() => {
          setIsDeleting(true)
          setDelta(deleteSpeed)
        }, delayTime)
        return
      }

      if (isDeleting && charIndex === 0) {
        setIsDeleting(false)
        // Move to next phrase
        setPhraseIndex((prev) => (prev + 1) % phrases.length)
        // Pause before next phrase
        setDelta(500)
      }
    }

    const timer = setTimeout(tick, delta)
    return () => clearTimeout(timer)
  }, [
    charIndex,
    delta,
    isDeleting,
    phraseIndex,
    phrases,
    typingSpeed,
    deleteSpeed,
    delayTime
  ])

  return (
    <div className="typing-container relative" ref={containerRef}>
      <h1 className="text-5xl md:text-7xl font-bold">
        Building{' '}
        <div
          className="inline-block relative"
          style={{ minWidth: `${longestPhrase.length * 0.6}ch` }}
        >
          <span className={`relative ${gradientText}`}>
            {text}
            <span
              className={`absolute ml-1 h-full w-2 bg-blue-400 top-0 ${
                isCursorBlinking ? 'animate-cursor-blink' : ''
              }`}
              style={{
                display: 'inline-block',
                marginTop: '5px',
                borderRadius: '1px',
                boxShadow: '0 0 8px rgba(66, 153, 225, 0.6)'
              }}
            ></span>
          </span>
        </div>
        <br />
        That Matter
      </h1>
    </div>
  )
}

export default TypingTitle
