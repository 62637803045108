import React from 'react'
import { Link } from 'react-router-dom'

/**
 * FOOTER COMPONENT
 * ===============
 * This component renders the site footer.
 *
 * POSITIONING NOTES:
 * - The footer has z-index:10 to ensure it stays below content
 * - It uses mt-auto in flex layouts to push to the bottom
 * - The sticky class is not used as it would interfere with scrolling
 */
const Footer: React.FC = () => {
  const year = new Date().getFullYear()

  return (
    <footer className="py-4 bg-[var(--color-cream-bg)]/80 dark:bg-gray-900/80 backdrop-blur-sm text-gray-700 dark:text-white border-t border-[#52babb]/10 transition-colors duration-500 relative z-10">
      <div className="container mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
        <div className="mb-2 sm:mb-0">
          <Link
            to="/port-inc"
            className="text-[#52babb] hover:text-[#3a8384] dark:hover:text-[#8aeaea] text-sm transition-colors duration-300"
          >
            Port Inc
          </Link>
          <span className="text-gray-500 dark:text-white/50 text-xs transition-colors duration-500">
            {' '}
            -{' '}
          </span>

          <Link
            to="/devlog"
            className="text-[#52babb] hover:text-[#3a8384] dark:hover:text-[#8aeaea] text-sm transition-colors duration-300"
          >
            Dev Log
          </Link>
          <span className='text-gray-500 dark:text-white/50 text-xs transition-colors duration-500'>
            {' '}
            -{' '}
          </span>

          <Link
            to='/sitemap'
            className='text-[#52babb] hover:text-[#3a8384] dark:hover:text-[#8aeaea] text-sm transition-colors duration-300'
          >
            Sitemap
          </Link>
        </div>
        <p className="text-gray-500 dark:text-white/50 text-xs transition-colors duration-500">
          &copy; {year} JKJR Digital Development
        </p>
      </div>
    </footer>
  )
}

export default Footer
