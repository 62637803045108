import React, { useEffect, useState, useRef } from 'react'

interface MorphSvgProps {
  width?: string | number
  height?: string | number
  paths: string[]
  duration?: number
  strokeColor?: string
  strokeWidth?: number
  fillColor?: string
  loop?: boolean
  className?: string
}

const MorphSvg: React.FC<MorphSvgProps> = ({
  width = 300,
  height = 300,
  paths,
  duration = 1000,
  strokeColor = '#3b82f6',
  strokeWidth = 2,
  fillColor = 'none',
  loop = true,
  className = ''
}) => {
  const [currentPathIndex, setCurrentPathIndex] = useState(0)
  const [currentPath, setCurrentPath] = useState(paths[0])
  const [isAnimating, setIsAnimating] = useState(false)
  const startTimeRef = useRef<number>(0)
  const requestRef = useRef<number>()

  // Function to interpolate between two SVG paths
  const interpolatePath = (pathA: string, pathB: string, progress: number) => {
    // Simple implementation - for real application, use a library like flubber
    // This is a basic implementation that only works with the same number of points

    // Extract commands and coordinates from path strings
    // This is simplified and doesn't handle all SVG path commands
    const extractCommands = (path: string) => {
      const commands: { command: string; coords: number[] }[] = []
      const regex = /([ML])([^ML]+)/g
      let match

      while ((match = regex.exec(path)) !== null) {
        const command = match[1]
        const coordStr = match[2].trim()
        const coords = coordStr.split(/[,\s]+/).map(parseFloat)
        commands.push({ command, coords })
      }

      return commands
    }

    // Extract commands from both paths
    const commandsA = extractCommands(pathA)
    const commandsB = extractCommands(pathB)

    // If different number of commands, return pathA for first half, pathB for second
    if (commandsA.length !== commandsB.length) {
      return progress < 0.5 ? pathA : pathB
    }

    // Interpolate between commands
    let result = ''
    for (let i = 0; i < commandsA.length; i++) {
      const cmdA = commandsA[i]
      const cmdB = commandsB[i]

      if (
        cmdA.command !== cmdB.command ||
        cmdA.coords.length !== cmdB.coords.length
      ) {
        // If commands don't match, snap to final path at 50%
        return progress < 0.5 ? pathA : pathB
      }

      // Interpolate coordinates
      const interpolatedCoords = cmdA.coords
        .map((coordA, j) => {
          const coordB = cmdB.coords[j]
          return coordA + (coordB - coordA) * progress
        })
        .join(' ')

      result += cmdA.command + interpolatedCoords
    }

    return result
  }

  // Animation loop
  const animate = (timestamp: number) => {
    if (!startTimeRef.current) startTimeRef.current = timestamp
    const elapsed = timestamp - startTimeRef.current
    const progress = Math.min(elapsed / duration, 1)

    const currentIdx = currentPathIndex
    const nextIdx = (currentPathIndex + 1) % paths.length

    // Interpolate between current path and next path
    const interpolated = interpolatePath(
      paths[currentIdx],
      paths[nextIdx],
      progress
    )
    setCurrentPath(interpolated)

    if (progress < 1) {
      // Continue animation
      requestRef.current = requestAnimationFrame(animate)
    } else {
      // End of animation
      setCurrentPathIndex(nextIdx)
      setIsAnimating(false)

      // If loop is true, start next animation
      if (loop) {
        setTimeout(() => {
          if (paths.length > 1) {
            startAnimation()
          }
        }, 500) // Small delay between animations
      }
    }
  }

  const startAnimation = () => {
    setIsAnimating(true)
    startTimeRef.current = 0
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current)
    }
    requestRef.current = requestAnimationFrame(animate)
  }

  // Start animation
  useEffect(() => {
    if (paths.length < 2) return

    startAnimation()

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
    }
  }, [currentPathIndex, paths.length])

  // Handle path changes
  useEffect(() => {
    if (!isAnimating && paths.length > 0) {
      setCurrentPath(paths[currentPathIndex])
    }
  }, [paths, currentPathIndex, isAnimating])

  return (
    <div className={`${className}`}>
      <svg
        width={width}
        height={height}
        viewBox='0 0 100 100'
        preserveAspectRatio='xMidYMid meet'
      >
        <path
          d={currentPath}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fill={fillColor}
          strokeLinejoin='round'
          strokeLinecap='round'
        />
      </svg>
    </div>
  )
}

// Predefined shapes
export const MorphShapes = {
  Circle: 'M50,20 A30,30 0 1,1 50,80 A30,30 0 1,1 50,20',
  Square: 'M20,20 L80,20 L80,80 L20,80 Z',
  Triangle: 'M50,20 L80,80 L20,80 Z',
  Star: 'M50,10 L61,40 L94,40 L67,60 L78,90 L50,70 L22,90 L33,60 L6,40 L39,40 Z',
  Heart:
    'M50,30 A20,20 0 0,1 90,30 A20,20 0 0,1 50,70 A20,20 0 0,1 10,30 A20,20 0 0,1 50,30 Z',
  Arrow: 'M20,50 L60,50 L60,30 L80,50 L60,70 L60,50'
}

export default MorphSvg
