import React, { useRef, useEffect, useState } from 'react'
import { AnomalyManager, NetworkDataStream } from '../ghost/Ghost'
import { useTheme } from '../../../contexts/ThemeContext'
import Ghost from '../ghost/Ghost'

/**
 * FULL VIEWPORT ANIMATION COMPONENT
 * =================================
 * This component renders a line art animation that spans the entire viewport.
 *
 * RELATED COMPONENTS:
 * - GlobalBackground (wraps this component in a fixed position container)
 * - ThemedApp (sets the z-index and overflow handling)
 *
 * VIEWPORT COVERAGE:
 * This component is designed to cover the entire viewport with an animated network.
 * The canvas is sized to match window.innerWidth and window.innerHeight with device pixel ratio.
 *
 * IMPLEMENTATION NOTES:
 * - The canvas size is set to 100vw/100vh via its containing element in GlobalBackground
 * - Fixed positioning (applied in GlobalBackground) ensures it stays anchored to the viewport
 * - z-index layering ensures it remains behind all content
 */

interface LineArtBackgroundProps {
  color?: string
  lineColor?: string
  nodeColor?: string
  nodeCount?: number
  speed?: number
  isHomePage?: boolean
}

const LineArtBackground: React.FC<LineArtBackgroundProps> = ({
  color = 'transparent',
  lineColor = 'rgba(150, 130, 220, 0.40)',
  nodeColor = 'rgba(150, 130, 220, 0.55)',
  nodeCount = 100,
  speed = 0.1,
  isHomePage = false
}) => {
  // Store initial dimensions to avoid reinitializing on small changes
  const [initialDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const animationRef = useRef<number>()
  const mousePosRef = useRef<{ x: number; y: number } | null>(null)
  const nodesRef = useRef<
    Array<{
      x: number
      y: number
      vx: number
      vy: number
      radius: number
      targetX: number
      targetY: number
      expansion: number
    }>
  >([])

  // Store viewport dimensions to detect changes
  const viewportRef = useRef<{ width: number; height: number }>({
    width: initialDimensions.width,
    height: initialDimensions.height
  })

  // Track last reinitialization time to prevent too frequent updates
  const lastInitTimeRef = useRef<number>(0)
  // Keep track of previous dimensions
  const previousDimensionsRef = useRef<{ width: number; height: number }>(
    initialDimensions
  )
  // Track if we're on mobile
  const isMobileRef = useRef<boolean>(initialDimensions.width <= 768)

  // Get current theme to pass to ghosts
  const { theme } = useTheme()
  const isDarkMode = theme === 'dark'

  // Calculate adaptive node count based on screen size
  const calculateNodeCount = (width: number, height: number): number => {
    // Use lower density for mobile
    const isMobile = width <= 768

    // Different densities for mobile vs desktop
    const baseDensity = isMobile
      ? (nodeCount * 3.0) / 1920000 // Even higher density for mobile
      : (nodeCount * 2.25) / 1920000 // Higher density for desktop

    // Calculate area in pixels
    const area = width * height

    // Apply a non-linear scaling with density factor
    const scaledCount = Math.round(
      baseDensity * area * Math.sqrt(area / (isMobile ? 800000 : 850000)) // Lower values = more nodes
    )

    // Increase min/max for mobile to allow even more nodes
    return isMobile
      ? Math.max(70, Math.min(250, scaledCount))
      : Math.max(60, Math.min(350, scaledCount))
  }

  // Determine if a resize is significant enough to reinitialize
  const isSignificantResize = (
    newWidth: number,
    newHeight: number
  ): boolean => {
    // On mobile, ignore small viewport changes completely
    if (isMobileRef.current) {
      // Get the original dimensions from when the component first mounted
      const originalWidth = initialDimensions.width
      const originalHeight = initialDimensions.height

      // Calculate percentage change from ORIGINAL dimensions (not previous)
      // This prevents cumulative small changes from eventually triggering a repaint
      const widthChangePct = Math.abs(newWidth - originalWidth) / originalWidth
      const heightChangePct =
        Math.abs(newHeight - originalHeight) / originalHeight

      // Only reinitialize on mobile if orientation changed or very significant size change
      const isOrientationChange =
        (originalWidth > originalHeight && newWidth < newHeight) ||
        (originalWidth < originalHeight && newWidth > newHeight)

      // For mobile, we want to be very strict about when we reinitialize
      // Only do it for:
      // 1. Orientation changes
      // 2. Really significant size changes (>25%) like split-screen mode
      // 3. Not too frequently (1500ms minimum between reinits)
      const timeSinceLastInit = Date.now() - lastInitTimeRef.current

      return (
        (isOrientationChange ||
          widthChangePct > 0.25 ||
          heightChangePct > 0.25) &&
        timeSinceLastInit > 1500
      )
    }

    // For desktop, normal rules apply
    return true
  }

  // Track mouse position without re-rendering
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      mousePosRef.current = { x: event.clientX, y: event.clientY }
    }

    const handleTouchMove = (event: TouchEvent) => {
      // Prevent default to stop scrolling while touching animation
      // Only use touch position for the animation effect
      if (event.touches.length > 0) {
        mousePosRef.current = {
          x: event.touches[0].clientX,
          y: event.touches[0].clientY
        }
      }
    }

    const handleTouchEnd = () => {
      // Reset the mouse position when touch ends
      setTimeout(() => {
        mousePosRef.current = null
      }, 500)
    }

    // Add event listeners with passive true to prevent scroll blocking
    window.addEventListener('mousemove', handleMouseMove, { passive: true })
    window.addEventListener('touchmove', handleTouchMove, { passive: true })
    window.addEventListener('touchend', handleTouchEnd, { passive: true })

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('touchmove', handleTouchMove)
      window.removeEventListener('touchend', handleTouchEnd)
    }
  }, [])

  // Initialize nodes based on current viewport with adaptive node count
  const initializeNodes = () => {
    try {
      const viewport = viewportRef.current

      // Update the last initialization time
      lastInitTimeRef.current = Date.now()

      // Update previous dimensions reference
      previousDimensionsRef.current = {
        width: viewport.width,
        height: viewport.height
      }

      // Calculate adaptive node count based on screen size
      const adaptiveNodeCount = calculateNodeCount(
        viewport.width,
        viewport.height
      )

      // For mobile, ensure we have a minimum number of nodes
      // Increased node count for mobile
      const isMobile = isMobileRef.current
      const finalNodeCount = isMobile
        ? Math.max(80, Math.min(220, adaptiveNodeCount))
        : adaptiveNodeCount

      // Apply a vertical offset to raise the graph (10% of height)
      const verticalOffset = -viewport.height * 0.1

      // Calculate center position
      const centerX = viewport.width / 2
      const centerY = viewport.height / 2

      // Create nodes starting at center with more natural random velocities
      nodesRef.current = []
      for (let i = 0; i < finalNodeCount; i++) {
        nodesRef.current.push({
          // Start all nodes at the center
          x: centerX,
          y: centerY,
          // Target position where the node will eventually end up
          targetX: Math.random() * viewport.width,
          targetY: Math.random() * viewport.height + verticalOffset,
          // Use original velocity values for natural motion after expansion
          vx: (Math.random() - 0.5) * (isMobile ? speed * 1.0 : speed * 0.9),
          vy: (Math.random() - 0.5) * (isMobile ? speed * 1.0 : speed * 0.9),
          radius: Math.random() * 2 + (isMobile ? 0.8 : 1),
          // Expansion progress (0-1)
          expansion: 0
        })
      }

      // Flag to track initial animation
      initialAnimationRef.current = true

      console.log(
        'LineArt: Initialized',
        nodesRef.current.length,
        'nodes at viewport',
        viewport.width,
        'x',
        viewport.height,
        isMobile ? '(mobile)' : ''
      )
    } catch (error) {
      console.error('Error initializing nodes:', error)
    }
  }

  // Reference to track initial animation
  const initialAnimationRef = useRef(true)
  // Track nova effect state
  const novaEffectRef = useRef({
    active: false,
    progress: 0,
    maxRadius: 0,
    duration: 1500, // Duration in ms
    startTime: 0
  })

  // Main component setup effect
  useEffect(() => {
    try {
      console.log('LineArt: Component mounted, isMobile:', isMobileRef.current)

      const canvas = canvasRef.current
      if (!canvas) {
        console.error('LineArt: Canvas reference is null')
        return
      }

      const ctx = canvas.getContext('2d', { alpha: true, desynchronized: true })
      if (!ctx) {
        console.error('LineArt: Failed to get 2d context')
        return
      }

      // Setup
      const dpr = window.devicePixelRatio || 1

      // Initialize canvas once with the current dimensions
      const setupCanvas = () => {
        try {
          if (!canvas || !ctx) return

          // Get actual viewport dimensions including any bottom scrollable areas
          const viewportWidth = window.innerWidth
          const viewportHeight = Math.max(
            window.innerHeight,
            document.documentElement.clientHeight
          )

          // Store current viewport dimensions
          viewportRef.current = {
            width: viewportWidth,
            height: viewportHeight
          }

          // Set display size (css pixels) to match viewport
          canvas.style.width = '100vw'
          canvas.style.height = '100vh'

          // Set actual pixel size accounting for device pixel ratio for sharpness
          canvas.width = Math.floor(viewportWidth * dpr)
          canvas.height = Math.floor(viewportHeight * dpr)

          // Scale context for device pixel ratio
          ctx.scale(dpr, dpr)

          // Ensure we also cover the bottom of viewport
          canvas.style.position = 'absolute'
          canvas.style.top = '0'
          canvas.style.left = '0'
          canvas.style.bottom = '0'
          canvas.style.right = '0'

          console.log(
            'LineArt: Canvas set to',
            canvas.width / dpr,
            'x',
            canvas.height / dpr,
            `(${canvas.width}x${canvas.height} actual pixels at ${dpr}x DPR)`
          )

          // Force initial clear with background color
          ctx.fillStyle = color
          ctx.fillRect(0, 0, viewportWidth, viewportHeight)

          // Initialize nodes after setting up canvas
          initializeNodes()
        } catch (error) {
          console.error('LineArt: Error in setupCanvas:', error)
        }
      }

      // Setup canvas once when component mounts
      setupCanvas()

      // Only add resize handler for desktop - on mobile we use the initial size
      if (!isMobileRef.current) {
        const handleResize = () => {
          try {
            const newWidth = window.innerWidth
            const newHeight = window.innerHeight

            // Skip resize handler on mobile completely
            if (window.innerWidth <= 768) {
              return
            }

            // Check if size actually changed
            const sizeChanged =
              newWidth !== viewportRef.current.width ||
              newHeight !== viewportRef.current.height

            if (sizeChanged && isSignificantResize(newWidth, newHeight)) {
              console.log(
                'LineArt: Significant desktop resize detected, updating'
              )

              // Update viewport
              viewportRef.current = { width: newWidth, height: newHeight }

              // Update canvas size
              canvas.style.width = `${newWidth}px`
              canvas.style.height = `${newHeight}px`
              canvas.width = Math.floor(newWidth * dpr)
              canvas.height = Math.floor(newHeight * dpr)

              // Reset context scale
              ctx.setTransform(1, 0, 0, 1, 0, 0)
              ctx.scale(dpr, dpr)

              // Reinitialize nodes
              initializeNodes()
            }
          } catch (error) {
            console.error('LineArt: Error in resize handler:', error)
          }
        }

        // Add resize listener for desktop only
        window.addEventListener('resize', handleResize)

        // Return cleanup function for desktop resize listener
        return () => {
          window.removeEventListener('resize', handleResize)
        }
      }
    } catch (error) {
      console.error('LineArt: Fatal setup error:', error)
    }
  }, [initialDimensions])

  // Animation loop in separate effect to avoid recreating it
  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    const animate = () => {
      try {
        const viewport = viewportRef.current
        const isMobile = isMobileRef.current

        // Background
        ctx.clearRect(0, 0, viewport.width, viewport.height)

        if (color !== 'transparent') {
          ctx.fillStyle = color
          ctx.fillRect(0, 0, viewport.width, viewport.height)
        }

        const nodes = nodesRef.current
        const mousePos = mousePosRef.current

        // Render nova effect at the beginning of animation
        const novaEffect = novaEffectRef.current
        if (
          initialAnimationRef.current &&
          !novaEffect.active &&
          nodes.length > 0
        ) {
          // Start nova effect when animation begins
          novaEffect.active = true
          novaEffect.progress = 0
          novaEffect.startTime = performance.now()
          novaEffect.maxRadius = Math.max(viewport.width, viewport.height) * 0.6
          console.log('Nova effect started')
        }

        // Update and render nova effect
        if (novaEffect.active) {
          const currentTime = performance.now()
          const elapsed = currentTime - novaEffect.startTime
          novaEffect.progress = Math.min(1, elapsed / novaEffect.duration)

          // Draw nova effect
          drawNovaEffect(
            ctx,
            viewport.width / 2,
            viewport.height / 2,
            novaEffect.progress,
            viewport,
            isDarkMode
          )

          // End nova effect when complete
          if (novaEffect.progress >= 1) {
            novaEffect.active = false
          }
        }

        // Update node positions
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i]

          // Handle initial expansion animation
          if (initialAnimationRef.current) {
            // Increase expansion progress with nova influence
            const novaInfluence = novaEffect.active
              ? Math.min(1, novaEffect.progress * 1.5)
              : 1
            node.expansion = Math.min(1, node.expansion + 0.006 * novaInfluence)

            // Interpolate between center and target position with light-bending effect
            const progress = easeOutQuad(node.expansion)
            const centerX = viewport.width / 2
            const centerY = viewport.height / 2

            // Apply light bending effect during expansion
            const bendFactor = Math.sin(node.expansion * Math.PI) * 0.2 // Max bend at middle of animation
            const angle = Math.atan2(
              node.targetY - centerY,
              node.targetX - centerX
            )
            const distance = Math.sqrt(
              Math.pow(node.targetX - centerX, 2) +
                Math.pow(node.targetY - centerY, 2)
            )

            // Create spiral effect by adjusting angle based on expansion progress
            const adjustedAngle =
              angle + bendFactor * (1 - node.expansion) * 2 * Math.PI

            // Calculate position with bend effect
            const bendDistance = distance * progress
            const bendX = centerX + Math.cos(adjustedAngle) * bendDistance
            const bendY = centerY + Math.sin(adjustedAngle) * bendDistance

            node.x = bendX
            node.y = bendY

            // Gradually increase velocity as expansion completes
            // This ensures a smooth transition to natural motion
            if (node.expansion > 0.9) {
              const transitionFactor = (node.expansion - 0.9) * 10 // 0-1 during last 10% of expansion
              // Blend between zero velocity and full velocity for smooth transition
              node.vx = node.vx * transitionFactor
              node.vy = node.vy * transitionFactor
            } else {
              // Keep velocity at zero during expansion
              node.vx = 0
              node.vy = 0
            }

            // If all nodes have reached full expansion, end initial animation
            if (node.expansion >= 1) {
              initialAnimationRef.current = false
            }

            // Skip remaining update logic during initial animation
            continue
          }

          // Apply anomaly effects to node velocity if any
          const nodeEffect = AnomalyManager.getNodeEffect(node.x, node.y, i)
          if (nodeEffect && nodeEffect.velocityChange) {
            node.vx += nodeEffect.velocityChange.x
            node.vy += nodeEffect.velocityChange.y
          }

          // Apply very subtle friction to gradually slow nodes (reduced motion)
          // But keep enough energy to maintain the original drift feeling
          node.vx *= 0.998
          node.vy *= 0.998

          // Add tiny random movement occasionally to prevent complete stopping
          if (Math.random() < 0.05) {
            node.vx += (Math.random() - 0.5) * 0.01
            node.vy += (Math.random() - 0.5) * 0.01
          }

          // Limit maximum velocity to maintain a calmer animation
          const currentSpeed = Math.sqrt(node.vx * node.vx + node.vy * node.vy)
          const maxSpeed = isMobile ? speed * 1.3 : speed
          if (currentSpeed > maxSpeed) {
            node.vx = (node.vx / currentSpeed) * maxSpeed
            node.vy = (node.vy / currentSpeed) * maxSpeed
          }

          node.x += node.vx
          node.y += node.vy

          // Wrap around viewport edges with padding
          const padding = isMobile ? 50 : 100
          if (node.x < -padding) node.x = viewport.width + padding
          if (node.x > viewport.width + padding) node.x = -padding
          if (node.y < -padding) node.y = viewport.height + padding
          if (node.y > viewport.height + padding) node.y = -padding

          // Register node data with the NetworkDataStream
          NetworkDataStream.registerNode(i, node.x, node.y, node.vx, node.vy)
        }

        // Draw connections (line art)
        ctx.lineWidth = isMobile ? 0.7 : 1.2

        // Maximum distance for connecting nodes
        const maxDistance = isMobile ? 150 : 200

        // Also optimize the connection drawing on mobile by skipping fewer nodes
        for (let i = 0; i < nodes.length; i++) {
          const nodeA = nodes[i]

          // Connect to nearby nodes to create line art
          // For mobile, we now only skip every other node for more connections
          for (
            let j = i + (isMobile ? 1 : 1);
            j < nodes.length;
            j += isMobile ? 2 : 1
          ) {
            const nodeB = nodes[j]
            const dx = nodeA.x - nodeB.x
            const dy = nodeA.y - nodeB.y
            const distance = Math.sqrt(dx * dx + dy * dy)

            if (distance < maxDistance) {
              // During initial animation, make lines more transparent
              let lineOpacity = 1 - distance / maxDistance
              if (initialAnimationRef.current) {
                // Fade in lines during expansion
                const avgExpansion = (nodeA.expansion + nodeB.expansion) / 2
                lineOpacity *= avgExpansion
              }

              // Change color based on mouse proximity
              if (mousePos) {
                const midX = (nodeA.x + nodeB.x) / 2
                const midY = (nodeA.y + nodeB.y) / 2
                const mouseDistance = Math.sqrt(
                  Math.pow(midX - mousePos.x, 2) +
                    Math.pow(midY - mousePos.y, 2)
                )

                if (mouseDistance < (isMobile ? 80 : 150)) {
                  const proximity = 1 - mouseDistance / (isMobile ? 80 : 150)
                  ctx.strokeStyle = `rgba(82, 186, 187, ${Math.min(
                    0.7,
                    0.3 + proximity * 0.4
                  )})`
                } else {
                  ctx.strokeStyle = lineColor
                }
              } else {
                ctx.strokeStyle = lineColor
              }

              // Check for anomaly effects on lines
              const lineEffect = AnomalyManager.getLineEffect(
                nodeA.x,
                nodeA.y,
                nodeB.x,
                nodeB.y
              )
              if (lineEffect) {
                if (lineEffect.color) {
                  ctx.strokeStyle = lineEffect.color
                }
                if (lineEffect.width) {
                  ctx.lineWidth = lineEffect.width
                }
                if (lineEffect.dash) {
                  ctx.setLineDash(lineEffect.dash)
                } else {
                  ctx.setLineDash([])
                }
              } else {
                ctx.setLineDash([])
              }

              // Register the line connection with NetworkDataStream
              NetworkDataStream.registerLine(i, j, distance, lineOpacity)

              ctx.globalAlpha = lineOpacity
              ctx.beginPath()
              ctx.moveTo(nodeA.x, nodeA.y)
              ctx.lineTo(nodeB.x, nodeB.y)
              ctx.stroke()
            }
          }
        }

        // Reset alpha and line dash
        ctx.globalAlpha = 1
        ctx.setLineDash([])

        // Draw nodes
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i]

          // Check for anomaly effects on nodes
          const nodeEffect = AnomalyManager.getNodeEffect(node.x, node.y, i)
          let nodeSizeMultiplier = isMobile ? 0.85 : 1.1

          if (nodeEffect) {
            if (nodeEffect.color) {
              ctx.fillStyle = nodeEffect.color
            } else if (mousePos) {
              const nodeX = node.x
              const nodeY = node.y
              const mouseDistance = Math.sqrt(
                Math.pow(nodeX - mousePos.x, 2) +
                  Math.pow(nodeY - mousePos.y, 2)
              )

              if (mouseDistance < (isMobile ? 70 : 100)) {
                const proximity = 1 - mouseDistance / (isMobile ? 70 : 100)
                ctx.fillStyle = `rgba(82, 186, 187, ${Math.min(
                  0.8,
                  0.5 + proximity * 0.3
                )})`
              } else {
                ctx.fillStyle = nodeColor
              }
            } else {
              ctx.fillStyle = nodeColor
            }

            if (nodeEffect.sizeMultiplier !== undefined) {
              nodeSizeMultiplier *= nodeEffect.sizeMultiplier
            }
          } else if (mousePos) {
            const nodeX = node.x
            const nodeY = node.y
            const mouseDistance = Math.sqrt(
              Math.pow(nodeX - mousePos.x, 2) + Math.pow(nodeY - mousePos.y, 2)
            )

            if (mouseDistance < (isMobile ? 70 : 100)) {
              const proximity = 1 - mouseDistance / (isMobile ? 70 : 100)
              ctx.fillStyle = `rgba(82, 186, 187, ${Math.min(
                0.8,
                0.5 + proximity * 0.3
              )})`
            } else {
              ctx.fillStyle = nodeColor
            }
          } else {
            ctx.fillStyle = nodeColor
          }

          // Only draw node if size multiplier is not zero
          if (nodeSizeMultiplier > 0) {
            ctx.beginPath()
            ctx.arc(
              node.x,
              node.y,
              node.radius * nodeSizeMultiplier,
              0,
              Math.PI * 2
            )
            ctx.fill()
          }
        }

        // Update and draw anomaly effects
        if (AnomalyManager.initialized) {
          AnomalyManager.update(mousePos, viewport.width, viewport.height)
          AnomalyManager.drawEffects(ctx, isDarkMode)
        }

        // Request next frame
        animationRef.current = requestAnimationFrame(animate)
      } catch (error) {
        console.error('LineArt: Animation error:', error)
        animationRef.current = requestAnimationFrame(animate)
      }
    }

    // Start animation
    animationRef.current = requestAnimationFrame(animate)

    // Initialize anomaly system if not already
    if (!AnomalyManager.initialized) {
      AnomalyManager.initialize(
        viewportRef.current.width,
        viewportRef.current.height
      )
    }

    // Clean up on unmount
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
      // Clear network data when unmounting
      NetworkDataStream.clear()
    }
  }, [color, lineColor, nodeColor])

  // Easing function for smoother animation
  function easeOutQuad(t: number): number {
    return t * (2 - t)
  }

  // Function to draw dwarf nova explosion effect
  function drawNovaEffect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    progress: number,
    viewport: { width: number; height: number },
    isDarkMode: boolean
  ) {
    // Save context state
    ctx.save()

    // Create radial gradient for nova
    const innerRadius =
      progress * (Math.min(viewport.width, viewport.height) * 0.5)
    const outerRadius = innerRadius * 1.3

    // Create multiple layers of the nova with different colors
    const layers = [
      {
        color: isDarkMode ? 'rgba(130, 87, 229, ' : 'rgba(180, 127, 250, ',
        size: 1.0
      },
      {
        color: isDarkMode ? 'rgba(90, 143, 255, ' : 'rgba(130, 183, 255, ',
        size: 1.15
      },
      {
        color: isDarkMode ? 'rgba(60, 120, 220, ' : 'rgba(100, 160, 255, ',
        size: 1.3
      }
    ]

    // Draw each layer of the nova
    for (const layer of layers) {
      // Use sin curve to make opacity peak in the middle of the animation
      const opacityFactor = Math.sin(progress * Math.PI)
      const layerOpacity = opacityFactor * (1 - progress) * 0.4

      const gradient = ctx.createRadialGradient(
        x,
        y,
        0,
        x,
        y,
        innerRadius * layer.size
      )

      gradient.addColorStop(0, `${layer.color}${layerOpacity * 1.0})`)
      gradient.addColorStop(0.4, `${layer.color}${layerOpacity * 0.7})`)
      gradient.addColorStop(0.7, `${layer.color}${layerOpacity * 0.4})`)
      gradient.addColorStop(1, `${layer.color}0)`)

      ctx.fillStyle = gradient
      ctx.beginPath()
      ctx.arc(x, y, outerRadius * layer.size, 0, Math.PI * 2)
      ctx.fill()
    }

    // Add light rays emanating from center
    const rayCount = 12
    const maxRayLength = Math.max(viewport.width, viewport.height) * 0.7

    // Rays are strongest in the middle of the animation
    const rayOpacity = Math.sin(progress * Math.PI) * 0.2

    ctx.strokeStyle = isDarkMode
      ? `rgba(150, 130, 255, ${rayOpacity})`
      : `rgba(180, 160, 255, ${rayOpacity})`
    ctx.lineWidth = 1

    for (let i = 0; i < rayCount; i++) {
      const angle = (i / rayCount) * Math.PI * 2
      // Rays grow and then shrink
      const rayLength = Math.sin(progress * Math.PI) * maxRayLength

      // Create wavy rays
      const endX = x + Math.cos(angle) * rayLength
      const endY = y + Math.sin(angle) * rayLength

      ctx.beginPath()
      ctx.moveTo(x, y)

      // Control points for bezier curve to create wave effect
      const cp1x = x + Math.cos(angle + 0.1) * (rayLength * 0.4)
      const cp1y = y + Math.sin(angle + 0.1) * (rayLength * 0.4)
      const cp2x = x + Math.cos(angle - 0.1) * (rayLength * 0.7)
      const cp2y = y + Math.sin(angle - 0.1) * (rayLength * 0.7)

      ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, endX, endY)
      ctx.stroke()
    }

    // Bright center of nova
    const centerOpacity = Math.sin(progress * Math.PI) * 0.9
    const centerGradient = ctx.createRadialGradient(
      x,
      y,
      0,
      x,
      y,
      innerRadius * 0.3
    )
    centerGradient.addColorStop(0, `rgba(255, 255, 255, ${centerOpacity})`)
    centerGradient.addColorStop(
      0.5,
      `rgba(220, 200, 255, ${centerOpacity * 0.5})`
    )
    centerGradient.addColorStop(1, `rgba(180, 180, 255, 0)`)

    ctx.fillStyle = centerGradient
    ctx.beginPath()
    ctx.arc(x, y, innerRadius * 0.4, 0, Math.PI * 2)
    ctx.fill()

    // Restore context
    ctx.restore()
  }

  // Render canvas with data-role attribute for easier debugging and bilateral documentation
  return (
    <>
      <canvas
        ref={canvasRef}
        className='absolute inset-0 w-full h-full'
        data-role='line-art-canvas'
      />
      <Ghost
        viewportWidth={window.innerWidth}
        viewportHeight={window.innerHeight}
        isHomePage={isHomePage}
      />
    </>
  )
}

export default LineArtBackground
