import React, { useState, useEffect } from 'react'
import Card from '../common/Card'
import WorkRequestForm from '../common/WorkRequestForm'
import { useTheme } from '../../contexts/ThemeContext'
import { useReset } from '../../contexts/ResetContext'

interface Technology {
  id: string
  title: string
  subtitle: string
  description: React.ReactNode
  defaultMinimized?: boolean
  detailedContent?: {
    leftColumn: React.ReactNode
    rightColumn: React.ReactNode
  }
}

const KnowledgeBase: React.FC = () => {
  const { theme } = useTheme()
  const { resetCount } = useReset()

  // Define theme-aware text colors
  const headingColor = theme === 'dark' ? 'text-white' : 'text-gray-800'
  const textColor = theme === 'dark' ? 'text-gray-300/90' : 'text-gray-600'

  const initialTechnologies: Technology[] = [
    {
      id: 'full-stack',
      title: 'Full Stack Solutions',
      subtitle: 'End-to-End Development',
      description:
        'Get your project built right from the ground up. Modern tech stack with Node.js, React, and TypeScript ensures scalable, maintainable applications. Benefit from live development, instant previews, and collaborative workflows.',
      defaultMinimized: true,
      detailedContent: {
        leftColumn: (
          <>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                What You Get
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Modern, scalable architecture
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Type-safe, maintainable code
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Rapid development cycles
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Best practices & patterns
                </li>
              </ul>
            </div>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Development Process
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Agile methodology
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Regular updates & demos
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Version control & CI/CD
                </li>
              </ul>
            </div>
          </>
        ),
        rightColumn: (
          <>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Tech Stack Benefits
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Fast, responsive applications
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  SEO-friendly architecture
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Mobile-first approach
                </li>
              </ul>
            </div>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Ongoing Support
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Performance monitoring
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Security updates
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Feature enhancements
                </li>
              </ul>
            </div>
          </>
        )
      }
    },
    {
      id: 'design',
      title: 'Modern UI Design',
      subtitle: 'Beautiful & Functional',
      description:
        'Transform your ideas into stunning, user-friendly interfaces. Get a modern design system powered by TailwindCSS, with dark mode support, responsive layouts, and smooth animations that work across all devices.',
      defaultMinimized: true,
      detailedContent: {
        leftColumn: (
          <>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Design Features
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Custom design system
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Dark/light themes
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Responsive layouts
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Brand consistency
                </li>
              </ul>
            </div>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                User Experience
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Intuitive navigation
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Fast load times
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Accessibility built-in
                </li>
              </ul>
            </div>
          </>
        ),
        rightColumn: (
          <>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Visual Elements
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Modern aesthetics
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Smooth animations
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Consistent components
                </li>
              </ul>
            </div>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Design Process
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Collaborative approach
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Iterative refinement
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  User testing
                </li>
              </ul>
            </div>
          </>
        )
      }
    },
    {
      id: 'interactive',
      title: 'Rich Interactions',
      subtitle: 'Engaging Experiences',
      description:
        'Elevate your user experience with smooth animations, intuitive interactions, and responsive feedback. From drag-and-drop interfaces to real-time updates, create engaging web applications that users love.',
      defaultMinimized: true,
      detailedContent: {
        leftColumn: (
          <>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Interactive Features
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Drag & drop interfaces
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Real-time updates
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Smooth transitions
                </li>
              </ul>
            </div>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                User Engagement
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Responsive feedback
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Touch optimized
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Cross-device support
                </li>
              </ul>
            </div>
          </>
        ),
        rightColumn: (
          <>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Performance
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  60 FPS animations
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Optimized rendering
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Smooth scrolling
                </li>
              </ul>
            </div>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Implementation
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  GSAP animations
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  React components
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Custom hooks
                </li>
              </ul>
            </div>
          </>
        )
      }
    },
    {
      id: 'cloud',
      title: 'Cloud & DevOps',
      subtitle: 'Reliable Infrastructure',
      description:
        'Deploy your application with confidence using modern cloud infrastructure. Get automated deployments, scalable hosting, and robust monitoring to ensure your application runs smoothly 24/7.',
      defaultMinimized: true,
      detailedContent: {
        leftColumn: (
          <>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Cloud Features
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Auto-scaling
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Load balancing
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  CDN integration
                </li>
              </ul>
            </div>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Deployment
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Automated CI/CD
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Zero-downtime updates
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Environment management
                </li>
              </ul>
            </div>
          </>
        ),
        rightColumn: (
          <>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Monitoring
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  24/7 uptime monitoring
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Performance metrics
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Error tracking
                </li>
              </ul>
            </div>
            <div>
              <h3 className={`text-lg font-semibold ${headingColor} mb-3`}>
                Security
              </h3>
              <ul className={`space-y-2 ${textColor}`}>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  SSL certificates
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  DDoS protection
                </li>
                <li className='flex items-center'>
                  <i className='fas fa-check text-[#52babb] mr-2'></i>
                  Security updates
                </li>
              </ul>
            </div>
          </>
        )
      }
    }
  ]

  const [technologies, setTechnologies] =
    useState<Technology[]>(initialTechnologies)
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null)
  const [activeFormIndex, setActiveFormIndex] = useState<number | null>(null)

  // Reset component state when resetCount changes
  useEffect(() => {
    // Reset technologies to initial state
    setTechnologies(initialTechnologies)
    // Close any open forms
    setActiveFormIndex(null)
  }, [resetCount])

  // Calculate if a card is in the right column (odd index in md:grid-cols-2)
  const isRightColumn = (index: number) => index % 2 === 1

  // Reorder cards when one is expanded
  const reorderForExpansion = (index: number) => {
    if (!isRightColumn(index)) return // Only reorder if expanding a right column card

    const newTechnologies = [...technologies]
    const expandedCard = newTechnologies[index]

    // Remove the card from its current position
    newTechnologies.splice(index, 1)

    // Find the next even index (left column) to insert the card
    const nextLeftColumnIndex = Math.ceil(index / 2) * 2

    // Insert the card at the new position
    newTechnologies.splice(nextLeftColumnIndex, 0, expandedCard)

    setTechnologies(newTechnologies)
  }

  const handleDragStart = (e: React.DragEvent, index: number) => {
    setDraggedIndex(index)
  }

  const handleDragEnter = (e: React.DragEvent, index: number) => {
    if (draggedIndex === null || draggedIndex === index) return

    const newTechnologies = [...technologies]
    const draggedTech = newTechnologies[draggedIndex]
    newTechnologies.splice(draggedIndex, 1)
    newTechnologies.splice(index, 0, draggedTech)
    setTechnologies(newTechnologies)
    setDraggedIndex(index)
  }

  const handleDragEnd = () => {
    setDraggedIndex(null)
  }

  const handleDragExpand = (index: number) => {
    reorderForExpansion(index)
  }

  const handleFormToggle = (isOpen: boolean, cardIndex: number) => {
    if (!isOpen) {
      // When a form is closed, clear the active form index
      setActiveFormIndex(null)
      return
    }

    // If a form is already open, don't open another one
    if (activeFormIndex !== null) {
      return
    }

    // Set the active form index without reordering
    setActiveFormIndex(cardIndex)
  }

  return (
    <div className='w-full px-0'>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6'>
        {technologies.map((tech, index) => (
          <React.Fragment key={tech.id}>
            <Card
              title={tech.title}
              subtitle={tech.subtitle}
              defaultMinimized={tech.defaultMinimized}
              index={index}
              onDragStart={handleDragStart}
              onDragEnter={handleDragEnter}
              onDragEnd={handleDragEnd}
              onDragExpand={handleDragExpand}
              onFormToggle={handleFormToggle}
              detailedContent={tech.detailedContent}
            >
              {tech.description}
            </Card>
            {activeFormIndex === index && (
              <div className='md:col-span-1'>
                <WorkRequestForm
                  cardTitle={tech.title}
                  onClose={() => handleFormToggle(false, index)}
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default KnowledgeBase
