import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import HomePage from '../pages/HomePage'
import Blog from '../pages/Blog'
import Projects from '../pages/Projects'
import ProjectContainer from '../pages/projects/[slug].container'
import Contact from '../pages/Contact'
import GlobalBackground from './layout/GlobalBackground'
import GlobalLayout from './layout/GlobalLayout'
import Overview from '../pages/Overview'
import DevLog from '../pages/DevLog'
import Portfolio from '../pages/Portfolio'
import Sitemap from '../pages/Sitemap'
import ExperimentalPage from '../pages/ExperimentalPage'
import AboutRelume from '../pages/AboutRelume'
import Resources from '../pages/Resources'

/**
 * THEMEDAPP - ROOT APPLICATION CONTAINER
 * =====================================
 * This component establishes the basic structure of the application,
 * including the full viewport background animation.
 *
 * RELATED COMPONENTS:
 * - GlobalBackground (provides the animated background)
 * - LineArtBackground (renders the animation)
 *
 * VIEWPORT ANIMATION INTEGRATION:
 * The component nests content inside GlobalBackground, which provides:
 * 1. Full viewport animation coverage via fixed positioning
 * 2. Proper z-index layering for content vs. animation
 * 3. Overflow handling to prevent layout issues
 *
 * CONTENT STRUCTURE:
 * - The flex-col and min-h-screen ensure the footer stays at the bottom
 * - z-10 ensures all content appears above the background animation
 */

const ThemedApp: React.FC = () => {
  // Configure future flags for React Router v7
  const routerOptions = {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true
    }
  }

  // Force viewport height CSS variable to ensure full viewport coverage
  useEffect(() => {
    const updateViewportHeight = () => {
      // Set a CSS variable for the actual viewport height
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      )
    }

    // Initial setup
    updateViewportHeight()

    // Update on resize
    window.addEventListener('resize', updateViewportHeight)

    // Cleanup
    return () => window.removeEventListener('resize', updateViewportHeight)
  }, [])

  return (
    <GlobalBackground>
      {/* 
        Main content wrapper
        This element ensures proper layering with the background animation.
        See GlobalBackground component for the animation container.
        Uses calc(var(--vh, 1vh) * 100) to ensure full height on mobile browsers.
      */}
      <div
        className='flex flex-col relative z-10 w-full max-w-full'
        style={{
          minHeight: 'calc(var(--vh, 1vh) * 100)'
        }}
        data-role='app-container'
      >
        <Header />
        <div className='flex-grow w-full max-w-full'>
          <Routes {...routerOptions}>
            <Route path='/' element={<HomePage />} />
            <Route
              path='/about'
              element={
                <GlobalLayout>
                  <Overview />
                </GlobalLayout>
              }
            />
            <Route
              path='/projects'
              element={
                <GlobalLayout>
                  <Projects />
                </GlobalLayout>
              }
            />
            <Route
              path='/blog'
              element={
                <GlobalLayout>
                  <Blog />
                </GlobalLayout>
              }
            />
            <Route
              path='/contact'
              element={
                <GlobalLayout>
                  <Contact />
                </GlobalLayout>
              }
            />
            <Route
              path='/devlog'
              element={
                <GlobalLayout>
                  <DevLog />
                </GlobalLayout>
              }
            />
            <Route
              path='/projects/:slug'
              element={
                <GlobalLayout>
                  <ProjectContainer />
                </GlobalLayout>
              }
            />
            <Route
              path='/blog/:slug'
              element={
                <GlobalLayout>
                  <Blog />
                </GlobalLayout>
              }
            />
            <Route
              path='/portfolio'
              element={
                <GlobalLayout>
                  <Portfolio />
                </GlobalLayout>
              }
            />
            <Route
              path='/sitemap'
              element={
                <GlobalLayout>
                  <Sitemap />
                </GlobalLayout>
              }
            />
            <Route
              path='/experimental'
              element={
                <GlobalLayout>
                  <ExperimentalPage />
                </GlobalLayout>
              }
            />
            <Route
              path='/about-relume'
              element={
                <GlobalLayout>
                  <AboutRelume />
                </GlobalLayout>
              }
            />
            <Route
              path='/resources'
              element={
                <GlobalLayout>
                  <Resources />
                </GlobalLayout>
              }
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </GlobalBackground>
  )
}

export default ThemedApp
