import React, { useState } from 'react'

interface TiltedProjectCardProps {
  title: string
  description: string
  tags: string[]
  link: string
  index: number
}

const TiltedProjectCard: React.FC<TiltedProjectCardProps> = ({
  title,
  description,
  tags,
  link,
  index
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const colors = ['#FF6B6B', '#48DBFB', '#1DD1A1', '#FD79A8', '#F7B731']
  const cardColor = colors[index % colors.length]

  return (
    <div
      className="w-full rounded-xl p-5 bg-white/60 dark:bg-gray-800/60 shadow-lg transition-all duration-300 backdrop-blur-sm"
      style={{
        transform: isHovered ? 'translateY(-10px)' : 'translateY(0)',
        boxShadow: isHovered
          ? `0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1), 0 0 0 2px ${cardColor}`
          : '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="mb-4">
        <h3 className="text-xl font-bold text-gray-800 dark:text-white">
          {title}
        </h3>
        <div
          className="h-1 w-20 mt-2 rounded"
          style={{ backgroundColor: cardColor }}
        ></div>
      </div>

      <p className="text-gray-600 dark:text-gray-300 mb-4">{description}</p>

      <div className="flex flex-wrap gap-2 mb-4">
        {tags.map((tag, i) => (
          <span
            key={i}
            className="px-2 py-1 text-xs rounded"
            style={{
              backgroundColor: `${cardColor}33`,
              color: cardColor
            }}
          >
            {tag}
          </span>
        ))}
      </div>

      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block px-4 py-2 rounded-lg text-white font-medium transition-all duration-300"
        style={{
          backgroundColor: cardColor,
          transform: isHovered ? 'scale(1.05)' : 'scale(1)'
        }}
      >
        View Project
      </a>

      {/* Comment: This would usually use react-tilt for a 3D effect */}
      <div className="absolute bottom-2 right-2 text-xs text-gray-400">
        {isHovered ? '3D Tilt effect coming soon' : ''}
      </div>
    </div>
  )
}

export default TiltedProjectCard
