import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTheme } from '../contexts/ThemeContext'
import { useReset } from '../contexts/ResetContext'
import logo from '../assets/JKJR3.png'

/**
 * GLOBAL LAYOUT COORDINATION
 * ==========================
 * The Header component sets critical padding values that must be mirrored
 * across the entire application's layout system.
 *
 * CRITICAL PADDING REQUIREMENTS:
 * -----------------------------
 * Current padding values: px-6 (for header)
 * Content areas use px-4 (for all other layouts)
 *
 * These padding values MUST be kept in sync with:
 * 1. src/components/layout/GlobalLayout.tsx (uses px-4)
 * 2. src/components/layouts/ProjectLayout.tsx (uses px-4)
 * 3. src/components/layouts/BlogLayout.tsx (uses px-4)
 *
 * The header intentionally uses more padding (px-6) than content areas (px-4)
 * to provide visual distinction and breathing room.
 */

const Header: React.FC = () => {
  const { theme, toggleTheme } = useTheme()
  const { resetApp } = useReset()
  const navigate = useNavigate()

  // Check system preference on mount
  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark')
    }
  }, [])

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault()
    resetApp()
    navigate('/')
  }

  const SocialIcons = () => (
    <div className='flex items-center space-x-4 relative z-50'>
      <a
        href='https://github.com/jeffknowlesjr'
        target='_blank'
        rel='noopener noreferrer'
        className='block p-2 text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white transition-colors relative z-50'
        aria-label='GitHub Profile'
      >
        <i className='fab fa-github text-xl'></i>
      </a>
      <a
        href='https://linkedin.com/in/jeffknowlesjr'
        target='_blank'
        rel='noopener noreferrer'
        className='block p-2 text-gray-700 dark:text-gray-300 hover:text-[#0A66C2] dark:hover:text-[#0A66C2] transition-colors relative z-50'
        aria-label='LinkedIn Profile'
      >
        <i className='fab fa-linkedin-in text-xl'></i>
      </a>
      <a
        href='https://facebook.com/git.infbirdsun'
        target='_blank'
        rel='noopener noreferrer'
        className='block p-2 text-gray-700 dark:text-gray-300 hover:text-[#1877F2] dark:hover:text-[#1877F2] transition-colors relative z-50'
        aria-label='Facebook Page'
      >
        <i className='fab fa-facebook-f text-xl'></i>
      </a>
      <a
        href='https://twitter.com/jeffknowlesjr'
        target='_blank'
        rel='noopener noreferrer'
        className='block p-2 text-gray-700 dark:text-gray-300 hover:text-[#1DA1F2] dark:hover:text-[#1DA1F2] transition-colors relative z-50'
        aria-label='Twitter Profile'
      >
        <i className='fab fa-twitter text-xl'></i>
      </a>
      <button
        onClick={toggleTheme}
        className='block p-2 text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors relative z-50'
        aria-label={
          theme === 'dark' ? 'Switch to Light Mode' : 'Switch to Dark Mode'
        }
      >
        <i
          className={`fas ${theme === 'dark' ? 'fa-sun' : 'fa-moon'} text-xl`}
        ></i>
      </button>
    </div>
  )

  return (
    <header className='relative z-40'>
      {/* Top Bar - transparent background */}
      <div className='bg-gray-100/60 dark:bg-gray-900/60 backdrop-blur-sm py-2'>
        {/* 
          IMPORTANT PADDING NOTE:
          Header uses px-6 padding, while content areas use px-4
          This provides more breathing room for the header elements
        */}
        <div className='flex justify-between items-center'>
          <Link
            to='/'
            className='flex items-center pl-6 hover:text-teal-600 dark:hover:text-teal-400 transition-colors'
          >
            <i className='fas fa-code text-teal-600 dark:text-teal-400 mr-2 text-lg'></i>
            <span className='text-gray-700 dark:text-gray-300 font-medium text-base'>
              Jeff Knowles Jr
              <span className='break-keep'> Digital Development</span>
            </span>
          </Link>
          {/* Desktop-only social icons */}
          <div className='hidden md:block pr-6'>
            <SocialIcons />
          </div>
        </div>
      </div>

      {/* Main Navigation - transparent background */}
      <nav className='bg-white/60 dark:bg-gray-800/60 backdrop-blur-sm shadow-sm'>
        {/* 
          IMPORTANT PADDING NOTE:
          Header uses px-6 padding, while content areas use px-4
          Other layout components should maintain px-4 for content padding
        */}
        <div className='flex justify-between items-center h-20 px-6'>
          {/* Logo */}
          <a
            href='https://www.jkjrdev.com'
            className='flex items-center flex-shrink-0'
            onClick={handleLogoClick}
          >
            <img
              src={logo}
              alt='JKJR Logo'
              className='h-10 w-auto dark:brightness-100 brightness-90 transition-all'
            />
            <span className='sr-only'>Jeff Knowles Jr</span>
          </a>

          {/* Desktop Menu - Commented out for future use */}
          <div className='hidden md:flex space-x-8'>
            {/* Menu items will go here */}
          </div>

          {/* Mobile social icons and theme toggle */}
          <div className='md:hidden'>
            <SocialIcons />
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
