import React, { createContext, useContext, useEffect, useState } from 'react'

type Theme = 'light' | 'dark'

interface ThemeContextType {
  theme: Theme
  toggleTheme: () => void
  isTransitioning: boolean
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

// Initialize theme synchronously before any components render
const initializeTheme = (): Theme => {
  // Check localStorage first
  if (typeof window !== 'undefined') {
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme === 'dark' || savedTheme === 'light') {
      return savedTheme
    }

    // Check cookie next
    const cookieTheme = document.cookie
      .split('; ')
      .find((row) => row.startsWith('theme='))
      ?.split('=')[1]
    if (cookieTheme === 'dark' || cookieTheme === 'light') {
      return cookieTheme
    }

    // Check system preference
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark'
    }
  }
  return 'light'
}

// Apply theme to document
const applyTheme = (theme: Theme) => {
  if (typeof window !== 'undefined') {
    document.documentElement.classList.remove('light', 'dark')
    document.documentElement.classList.add(theme)

    // Update localStorage and cookie
    localStorage.setItem('theme', theme)
    document.cookie = `theme=${theme}; path=/; max-age=${365 * 24 * 60 * 60}` // 1 year
  }
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}

interface ThemeProviderProps {
  children: React.ReactNode
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(initializeTheme)
  const [isTransitioning, setIsTransitioning] = useState(false)

  // Apply theme on mount and when it changes
  useEffect(() => {
    setIsTransitioning(true)
    applyTheme(theme)

    // Add transition class to body
    document.body.style.transition = 'background-color 0.5s ease'

    const timer = setTimeout(() => {
      setIsTransitioning(false)
    }, 500)

    return () => clearTimeout(timer)
  }, [theme])

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, isTransitioning }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
