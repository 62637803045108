import React, { useRef, useEffect } from 'react'

interface ParallaxSectionProps {
  layers: {
    image: string
    speed: number
    zIndex: number
    opacity?: number
  }[]
  height?: string
  className?: string
}

const ParallaxSection: React.FC<ParallaxSectionProps> = ({
  layers,
  height = '100vh',
  className = ''
}) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const layerRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    const section = sectionRef.current
    if (!section) return

    // Initialize layer positions
    layerRefs.current.forEach((layer, i) => {
      if (layer) {
        layer.style.transform = 'translateY(0px)'
      }
    })

    // Parallax effect on scroll
    const handleScroll = () => {
      const scrollTop = window.pageYOffset
      const sectionTop = section.offsetTop
      const sectionHeight = section.offsetHeight

      // Only apply effect when section is in view
      if (
        scrollTop + window.innerHeight > sectionTop &&
        scrollTop < sectionTop + sectionHeight
      ) {
        const sectionProgress =
          (scrollTop - sectionTop + window.innerHeight) /
          (window.innerHeight + sectionHeight)

        layerRefs.current.forEach((layer, i) => {
          if (layer) {
            // Different speed for different layers
            const yMove = sectionProgress * layers[i].speed * 100
            layer.style.transform = `translateY(${yMove}px)`
          }
        })
      }
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll() // Initialize positions

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [layers])

  return (
    <div
      ref={sectionRef}
      className={`relative overflow-hidden ${className}`}
      style={{ height }}
    >
      {layers.map((layer) => (
        <div
          key={layer.image}
          ref={(el) => layerRefs.current.push(el)}
          className='absolute w-full h-full transition-transform duration-200'
          style={{
            zIndex: layer.zIndex,
            backgroundImage: `url(${layer.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: layer.opacity || 1
          }}
        />
      ))}
    </div>
  )
}

export default ParallaxSection
