import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import { BlogPost as BlogPostType } from '../../types/blog'
import { normalizeImagePath, handleImageError } from '../../utils/imageUtils'

interface BlogPostProps {
  post: BlogPostType
}

export const BlogPost: React.FC<BlogPostProps> = ({ post }) => {
  return (
    <article className='mx-auto max-w-screen-xl py-4 sm:py-8 w-full overflow-x-hidden'>
      <header className='mb-4 sm:mb-8'>
        <h1 className='mb-3 sm:mb-4 text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 dark:text-gray-50 break-words'>
          {post.title}
        </h1>
        <div className='space-y-2 sm:space-y-3'>
          <div className='flex flex-wrap items-center gap-2 sm:gap-4 text-sm text-gray-600 dark:text-gray-300'>
            <span>{post.author}</span>
            <span>•</span>
            <span>{new Date(post.publishedAt).toLocaleDateString()}</span>
            <span>•</span>
            <span>{post.readingTime} min read</span>
          </div>
          <div className='flex flex-wrap gap-2 pt-1'>
            {post.tags.map((tag) => (
              <span
                key={tag}
                className='rounded-full bg-gray-200 dark:bg-gray-700 px-2 sm:px-3 py-1 text-xs sm:text-sm text-gray-700 dark:text-gray-200'
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
      </header>

      {post.featuredImage && (
        <div className='relative mb-4 sm:mb-8'>
          <div className='relative pb-[56.25%] rounded-lg overflow-hidden'>
            <img
              src={normalizeImagePath(post.featuredImage)}
              alt={post.title}
              className='absolute inset-0 w-full h-full object-cover'
              onLoad={() =>
                console.log(
                  '✅ Featured image loaded successfully:',
                  post.featuredImage
                )
              }
              onError={handleImageError}
            />
          </div>
        </div>
      )}

      <div className='overflow-x-hidden w-full'>
        <div
          className='prose prose-sm sm:prose-base lg:prose-lg dark:prose-invert w-full max-w-none
          prose-headings:text-gray-900 dark:prose-headings:text-gray-50 prose-headings:break-words
          prose-p:text-gray-700 dark:prose-p:text-gray-200
          prose-strong:text-gray-900 dark:prose-strong:text-gray-50
          prose-ul:text-gray-700 dark:prose-ul:text-gray-200
          prose-ol:text-gray-700 dark:prose-ol:text-gray-200
          prose-li:text-gray-700 dark:prose-li:text-gray-200 
          prose-a:text-primary hover:prose-a:text-primary-dark dark:prose-a:text-primary-light dark:hover:prose-a:text-primary prose-a:break-words
          prose-code:text-gray-800 dark:prose-code:text-gray-100 prose-code:bg-gray-100/60 dark:prose-code:bg-gray-800/60 prose-code:px-1 prose-code:py-0.5 prose-code:rounded prose-code:text-sm prose-code:font-mono prose-code:break-words prose-code:whitespace-pre-wrap
          prose-pre:bg-gray-100/40 dark:prose-pre:bg-gray-800/40 prose-pre:backdrop-blur-md prose-pre:rounded-lg prose-pre:p-2 sm:prose-pre:p-4 prose-pre:my-4 
          prose-pre:overflow-x-auto prose-pre:max-w-full
          prose-pre:prose-code:bg-transparent prose-pre:prose-code:p-0 prose-pre:prose-code:whitespace-pre
          prose-blockquote:text-gray-700 dark:prose-blockquote:text-gray-200
          prose-hr:border-gray-300 dark:prose-hr:border-gray-600
          prose-img:rounded-lg prose-img:mx-auto prose-img:max-w-full
          [&_table]:w-full [&_table]:overflow-hidden [&_table]:border-collapse
          [&_thead]:border-b [&_thead]:border-gray-300 [&_thead_dark]:border-gray-600
          [&_th]:p-2 [&_th]:text-left [&_th]:font-semibold
          [&_td]:p-2 [&_td]:border-b [&_td]:border-gray-200 [&_td_dark]:border-gray-700
          [&_tr:last-child_td]:border-0'
        >
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw, rehypeSanitize]}
            components={{
              pre: ({ children, ...props }) => (
                <div
                  className='overflow-x-auto w-full'
                  style={{ maxWidth: '100%' }}
                >
                  <pre {...props}>{children}</pre>
                </div>
              ),
              table: ({ children, ...props }) => (
                <div
                  className='overflow-x-auto w-full'
                  style={{ maxWidth: '100%' }}
                >
                  <table {...props}>{children}</table>
                </div>
              ),
              img: ({ src, alt }) => (
                <img
                  src={src}
                  alt={alt}
                  style={{ maxWidth: '100%' }}
                  className='mx-auto rounded-lg'
                />
              )
            }}
          >
            {post.content}
          </ReactMarkdown>
        </div>
      </div>
    </article>
  )
}
