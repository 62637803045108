import React, { useRef, useEffect } from 'react'

/**
 * ANOMALY TYPES ENUM
 * Different types of subtle anomalies that can appear in the background
 */
export enum AnomalyType {
  SHIMMER = 'shimmer',
  PULSE = 'pulse',
  BLUR = 'blur',
  DISTORTION = 'distortion',
  GLITCH = 'glitch',
  PHANTOM = 'phantom',
  SHADOW = 'shadow'
}

// Configuration for each anomaly type
const ANOMALY_CONFIG = {
  [AnomalyType.SHIMMER]: {
    frames: 8,
    color: 'rgba(200, 213, 255, 0.18)',
    size: 25,
    speed: 0.5,
    attractionStrength: 0.01,
    effectRadius: 50
  },
  [AnomalyType.PULSE]: {
    frames: 6,
    color: 'rgba(150, 130, 220, 0.15)',
    size: 35,
    speed: 0.4,
    attractionStrength: 0.005,
    effectRadius: 60
  },
  [AnomalyType.BLUR]: {
    frames: 12,
    color: 'rgba(124, 173, 255, 0.12)',
    size: 40,
    speed: 0.3,
    attractionStrength: 0.01,
    effectRadius: 65
  },
  [AnomalyType.DISTORTION]: {
    frames: 10,
    color: 'rgba(140, 255, 237, 0.12)',
    size: 30,
    speed: 0.6,
    attractionStrength: 0.02,
    effectRadius: 55
  },
  [AnomalyType.GLITCH]: {
    frames: 4,
    color: 'rgba(200, 200, 220, 0.15)',
    size: 20,
    speed: 0.7,
    attractionStrength: 0.01,
    effectRadius: 40
  },
  [AnomalyType.PHANTOM]: {
    frames: 8,
    color: 'rgba(100, 100, 150, 0.15)',
    size: 30,
    speed: 0.5,
    attractionStrength: 0.01,
    effectRadius: 50
  },
  [AnomalyType.SHADOW]: {
    frames: 6,
    color: 'rgba(50, 50, 100, 0.15)',
    size: 20,
    speed: 0.4,
    attractionStrength: 0.005,
    effectRadius: 40
  }
}

// Data stream to track and use network information
const NetworkDataStream = {
  activeNodes: new Map<
    number,
    { x: number; y: number; velocity: number; connections: number }
  >(),
  activeLines: new Map<string, { strength: number; length: number }>(),
  highActivityAreas: [] as { x: number; y: number; intensity: number }[],
  lastUpdate: 0,
  updateInterval: 500, // Update data stream less frequently (500ms instead of 300ms)

  registerNode(
    nodeIndex: number,
    x: number,
    y: number,
    vx: number,
    vy: number
  ) {
    const velocity = Math.sqrt(vx * vx + vy * vy)
    const existing = this.activeNodes.get(nodeIndex)

    if (!existing) {
      this.activeNodes.set(nodeIndex, {
        x,
        y,
        velocity,
        connections: 0
      })
    } else {
      existing.x = x
      existing.y = y
      existing.velocity = velocity
    }
  },

  registerLine(
    nodeIndex1: number,
    nodeIndex2: number,
    distance: number,
    opacity: number
  ) {
    const id =
      nodeIndex1 < nodeIndex2
        ? `${nodeIndex1}-${nodeIndex2}`
        : `${nodeIndex2}-${nodeIndex1}`

    this.activeLines.set(id, { strength: opacity, length: distance })

    // Increment connection count for both nodes
    const node1 = this.activeNodes.get(nodeIndex1)
    const node2 = this.activeNodes.get(nodeIndex2)

    if (node1) node1.connections++
    if (node2) node2.connections++
  },

  // Find areas with high activity (many connections, high velocity)
  updateHighActivityAreas() {
    const now = performance.now()
    if (now - this.lastUpdate < this.updateInterval) return

    this.highActivityAreas = []
    this.lastUpdate = now

    // Clear previous high activity areas
    if (this.activeNodes.size === 0) return

    // Find nodes with high connection counts
    let maxConnections = 0
    for (const node of this.activeNodes.values()) {
      maxConnections = Math.max(maxConnections, node.connections)
    }

    const connectionThreshold = Math.max(2, maxConnections * 0.6)

    // Create activity areas around high-connection nodes
    for (const node of this.activeNodes.values()) {
      if (node.connections >= connectionThreshold) {
        this.highActivityAreas.push({
          x: node.x,
          y: node.y,
          intensity: node.connections / maxConnections
        })
      }
    }

    // Reset connection counts for next cycle
    for (const node of this.activeNodes.values()) {
      node.connections = 0
    }
  },

  // Get nearest high activity area to a point
  getNearestActivityArea(
    x: number,
    y: number,
    maxDistance: number
  ): { position: { x: number; y: number }; intensity: number } | null {
    if (this.highActivityAreas.length === 0) return null

    let nearestDist = Number.MAX_VALUE
    let nearest = null

    for (const area of this.highActivityAreas) {
      const dx = area.x - x
      const dy = area.y - y
      const distSquared = dx * dx + dy * dy

      if (distSquared < nearestDist && Math.sqrt(distSquared) < maxDistance) {
        nearestDist = distSquared
        nearest = area
      }
    }

    if (nearest) {
      return {
        position: { x: nearest.x, y: nearest.y },
        intensity: nearest.intensity
      }
    }

    return null
  },

  clear() {
    this.activeNodes.clear()
    this.activeLines.clear()
    this.highActivityAreas = []
  }
}

interface GhostProps {
  type?: AnomalyType
  viewportWidth: number
  viewportHeight: number
  isHomePage?: boolean
}

// Performance monitoring
const PerformanceMonitor = {
  frameTimes: [] as number[],
  lastFrameTime: 0,
  frameCount: 0,
  averageFrameTime: 0,
  maxFramesTracked: 120, // Track 2 seconds at 60fps

  startFrame() {
    this.lastFrameTime = performance.now()
  },

  endFrame() {
    const now = performance.now()
    const frameDuration = now - this.lastFrameTime

    this.frameTimes.push(frameDuration)

    // Keep only the last N frames
    if (this.frameTimes.length > this.maxFramesTracked) {
      this.frameTimes.shift()
    }

    // Calculate average every 60 frames
    this.frameCount++
    if (this.frameCount % 60 === 0) {
      this.averageFrameTime =
        this.frameTimes.reduce((a, b) => a + b, 0) / this.frameTimes.length
      console.log(
        `Animation Performance: ${this.averageFrameTime.toFixed(2)}ms/frame (${(
          1000 / this.averageFrameTime
        ).toFixed(1)} FPS)`
      )

      // Alert if performance is poor
      if (this.averageFrameTime > 16.67) {
        // Less than 60fps
        console.warn(
          `Performance warning: ${this.averageFrameTime.toFixed(
            2
          )}ms/frame is below 60fps target`
        )
      }
    }
  },

  reset() {
    this.frameTimes = []
    this.lastFrameTime = 0
    this.frameCount = 0
    this.averageFrameTime = 0
  }
}

class AnomalyEntity {
  x: number
  y: number
  vx: number
  vy: number
  type: AnomalyType
  size: number
  frame: number
  frameCount: number
  frameTick: number
  color: string
  speed: number
  attractionStrength: number
  targetX: number | null
  targetY: number | null
  attractionRadius: number
  effectRadius: number
  timeOffset: number
  affectedNodes: Set<number>
  attractedToActivity: boolean
  activityAttractionStrength: number
  fleeing: boolean
  clickCount: number
  stuckToCursor: boolean
  lastClickTime: number
  clickTimeout: number
  isPixelated: boolean = false
  isHotDog: boolean = false

  constructor(
    x: number,
    y: number,
    type: AnomalyType,
    viewportWidth: number,
    viewportHeight: number
  ) {
    // Get anomaly configuration based on type
    const config = ANOMALY_CONFIG[type]

    this.x = x
    this.y = y
    this.type = type
    this.size = config.size
    this.color = config.color
    this.speed = config.speed
    this.attractionStrength = config.attractionStrength
    this.effectRadius = config.effectRadius

    // Random initial velocity
    const angle = Math.random() * Math.PI * 2
    this.vx = Math.cos(angle) * this.speed
    this.vy = Math.sin(angle) * this.speed

    // Animation properties
    this.frame = 0
    this.frameCount = config.frames
    this.frameTick = 0
    this.timeOffset = Math.random() * 100

    // Attraction properties
    this.targetX = null
    this.targetY = null
    this.attractionRadius = Math.min(viewportWidth, viewportHeight) / 4 // One quarter of the smaller dimension

    // Track which nodes are affected
    this.affectedNodes = new Set<number>()

    // Activity attraction properties
    this.attractedToActivity = Math.random() > 0.3 // 70% of anomalies attracted to activity
    this.activityAttractionStrength =
      this.attractionStrength * (0.8 + Math.random() * 0.4)

    // Fleeing and interaction properties
    this.fleeing = false
    this.clickCount = 0
    this.stuckToCursor = false
    this.lastClickTime = 0
    this.clickTimeout = 1000 // 1 second timeout between clicks
  }

  update(
    mousePos: { x: number; y: number } | null,
    viewportWidth: number,
    viewportHeight: number
  ) {
    // Update animation frame less frequently
    this.frameTick++
    if (this.frameTick > 15) {
      // Slower animation (15 instead of 10)
      this.frame = (this.frame + 1) % this.frameCount
      this.frameTick = 0
    }

    // If stuck to cursor, just follow it
    if (this.stuckToCursor && mousePos) {
      this.x = mousePos.x
      this.y = mousePos.y
      return
    }

    // Check if mouse is moving toward the anomaly (chasing it)
    if (mousePos && !this.fleeing) {
      const dx = mousePos.x - this.x
      const dy = mousePos.y - this.y
      const distanceSquared = dx * dx + dy * dy

      // Check if mouse is within detection range
      if (distanceSquared < this.attractionRadius * this.attractionRadius) {
        // Calculate the mouse velocity direction
        const mouseVelocityX = mousePos.x - (this.targetX || mousePos.x)
        const mouseVelocityY = mousePos.y - (this.targetY || mousePos.y)

        // Update stored target position
        this.targetX = mousePos.x
        this.targetY = mousePos.y

        // If the mouse is moving toward the anomaly, start fleeing
        if (
          mouseVelocityX * dx + mouseVelocityY * dy > 0 &&
          Math.sqrt(
            mouseVelocityX * mouseVelocityX + mouseVelocityY * mouseVelocityY
          ) > 3
        ) {
          this.fleeing = true
          setTimeout(() => (this.fleeing = false), 2000 + Math.random() * 2000) // Stop fleeing after 2-4 seconds
        }
      } else {
        this.targetX = null
        this.targetY = null
      }
    }

    // If fleeing from cursor
    if (this.fleeing && mousePos) {
      const dx = this.x - mousePos.x
      const dy = this.y - mousePos.y
      const distance = Math.sqrt(dx * dx + dy * dy)

      if (distance > 0) {
        // Normalize and apply repulsion with higher speed
        const nx = dx / distance
        const ny = dy / distance
        const repulsionStrength = this.attractionStrength * 8

        this.vx += nx * repulsionStrength
        this.vy += ny * repulsionStrength

        // Higher speed limit when fleeing
        const currentSpeed = Math.sqrt(this.vx * this.vx + this.vy * this.vy)
        if (currentSpeed > this.speed * 3) {
          this.vx = (this.vx / currentSpeed) * this.speed * 3
          this.vy = (this.vy / currentSpeed) * this.speed * 3
        }
      }
    }
    // Not fleeing - handle normal attraction (existing code)
    else if (mousePos) {
      const dx = mousePos.x - this.x
      const dy = mousePos.y - this.y
      const distanceSquared = dx * dx + dy * dy

      if (distanceSquared < this.attractionRadius * this.attractionRadius) {
        // Calculate normalized attraction vector
        const distance = Math.sqrt(distanceSquared)
        const nx = dx / distance
        const ny = dy / distance

        // Attraction strength decreases as we get closer to the target
        const strength =
          this.attractionStrength * (distance / this.attractionRadius)

        // Apply attraction
        this.vx += nx * strength
        this.vy += ny * strength

        // Limit velocity
        const currentSpeed = Math.sqrt(this.vx * this.vx + this.vy * this.vy)
        if (currentSpeed > this.speed * 1.5) {
          this.vx = (this.vx / currentSpeed) * this.speed * 1.5
          this.vy = (this.vy / currentSpeed) * this.speed * 1.5
        }

        // Update target for effects
        this.targetX = mousePos.x
        this.targetY = mousePos.y
      } else {
        // Reset target when mouse moves out of range
        this.targetX = null
        this.targetY = null
      }
    }

    // Check for high activity areas with reduced attraction
    if (!this.fleeing && this.attractedToActivity) {
      const nearestActivity = NetworkDataStream.getNearestActivityArea(
        this.x,
        this.y,
        this.attractionRadius * 1.2 // Reduced range (1.2 instead of 1.5)
      )

      if (nearestActivity) {
        const dx = nearestActivity.position.x - this.x
        const dy = nearestActivity.position.y - this.y
        const distance = Math.sqrt(dx * dx + dy * dy)

        if (distance > 0) {
          const nx = dx / distance
          const ny = dy / distance
          // Reduce activity attraction strength by half
          const strength =
            this.activityAttractionStrength * nearestActivity.intensity * 0.5

          this.vx += nx * strength
          this.vy += ny * strength

          // Lower velocity limit
          const currentSpeed = Math.sqrt(this.vx * this.vx + this.vy * this.vy)
          if (currentSpeed > this.speed * 1.5) {
            this.vx = (this.vx / currentSpeed) * this.speed * 1.5
            this.vy = (this.vy / currentSpeed) * this.speed * 1.5
          }
        }
      }
    }

    // Add less random movement
    if (Math.random() < 0.02) {
      // Lower probability (0.02 instead of 0.03)
      this.vx += (Math.random() - 0.5) * 0.2 // Reduced random movement (0.2 instead of 0.3)
      this.vy += (Math.random() - 0.5) * 0.2
    }

    // Update position
    this.x += this.vx
    this.y += this.vy

    // Wrap around screen edges with padding
    const padding = this.size
    if (this.x < -padding) this.x = viewportWidth + padding
    if (this.x > viewportWidth + padding) this.x = -padding
    if (this.y < -padding) this.y = viewportHeight + padding
    if (this.y > viewportHeight + padding) this.y = -padding

    // Clear affected nodes
    this.affectedNodes.clear()
  }

  // Check if a node is affected by this anomaly and how strongly
  affectsNode(nodeX: number, nodeY: number, nodeIndex: number): number {
    const dx = nodeX - this.x
    const dy = nodeY - this.y
    const distSquared = dx * dx + dy * dy

    // Skip if outside effect radius
    if (distSquared > this.effectRadius * this.effectRadius) {
      return 0
    }

    // Calculate influence (stronger in the center, fades to edges)
    const distance = Math.sqrt(distSquared)
    const influence = (1 - distance / this.effectRadius) * 0.7 // Reduce overall influence by 30%

    // Add to affected nodes set for later access
    if (influence > 0.15) {
      // Higher threshold for affecting nodes
      this.affectedNodes.add(nodeIndex)
    }

    return influence
  }

  // Check if a line between two nodes should be affected
  affectsLine(x1: number, y1: number, x2: number, y2: number): number {
    // Calculate the midpoint of the line
    const midX = (x1 + x2) / 2
    const midY = (y1 + y2) / 2

    // Calculate distance from anomaly to midpoint
    const dx = midX - this.x
    const dy = midY - this.y
    const distSquared = dx * dx + dy * dy

    // Skip if outside effect radius
    if (distSquared > this.effectRadius * this.effectRadius) {
      return 0
    }

    // Calculate influence (stronger in the center, fades to edges)
    const distance = Math.sqrt(distSquared)
    const influence = (1 - distance / this.effectRadius) * 0.6 // Reduce overall influence by 40%

    return influence
  }

  // Apply shimmer effect to a canvas context
  applyEffectToContext(ctx: CanvasRenderingContext2D, isDarkMode: boolean) {
    // Save context
    ctx.save()

    // If it's a hot dog (April Fools)
    if (this.isHotDog) {
      this.drawHotDog(ctx)
      ctx.restore()
      return
    }

    // If we're in October, apply pixelation effect
    if (this.isPixelated) {
      // Apply pixelation by drawing with larger blocks
      ctx.imageSmoothingEnabled = false
    }

    // Adjust color based on theme
    let anomalyColor = this.color
    if (isDarkMode) {
      anomalyColor = anomalyColor.replace(/[\d.]+\)$/, (m) => {
        const opacity = parseFloat(m.replace(')', ''))
        return `${Math.max(0.03, opacity * 0.6)})`
      })
    } else {
      // Even more subtle in light mode
      anomalyColor = anomalyColor.replace(/[\d.]+\)$/, (m) => {
        const opacity = parseFloat(m.replace(')', ''))
        return `${Math.max(0.02, opacity * 0.5)})`
      })
    }

    // Different effect based on type
    switch (this.type) {
      case AnomalyType.SHIMMER:
        this.applyShimmerEffect(ctx, anomalyColor)
        break
      case AnomalyType.PULSE:
        this.applyPulseEffect(ctx, anomalyColor)
        break
      case AnomalyType.BLUR:
        this.applyBlurEffect(ctx, anomalyColor)
        break
      case AnomalyType.DISTORTION:
        this.applyDistortionEffect(ctx, anomalyColor)
        break
      case AnomalyType.GLITCH:
        this.applyGlitchEffect(ctx, anomalyColor)
        break
    }

    ctx.restore()
  }

  applyShimmerEffect(ctx: CanvasRenderingContext2D, color: string) {
    const size = this.size
    const time = (this.frame + this.timeOffset) / 12 // Slower animation

    // Very faint glow
    const gradient = ctx.createRadialGradient(
      this.x,
      this.y,
      0,
      this.x,
      this.y,
      size
    )

    gradient.addColorStop(0, color.replace(/[\d.]+\)$/, '0.07)')) // Lower opacity
    gradient.addColorStop(0.5, color.replace(/[\d.]+\)$/, '0.03)'))
    gradient.addColorStop(1, color.replace(/[\d.]+\)$/, '0)'))

    ctx.fillStyle = gradient
    ctx.globalAlpha = 0.2 + Math.sin(time) * 0.1 // Less variation
    ctx.beginPath()
    ctx.arc(this.x, this.y, size, 0, Math.PI * 2)
    ctx.fill()

    // Fewer sparkles
    if (Math.random() > 0.6) {
      // Only 40% chance to show sparkles
      const sparkleCount = 1 + Math.floor(Math.random() * 2) // 1-2 sparkles
      ctx.fillStyle = color.replace(/[\d.]+\)$/, '0.25)') // Lower opacity

      for (let i = 0; i < sparkleCount; i++) {
        const angle = time + (i * Math.PI * 2) / sparkleCount
        const distance = size * 0.5 * Math.random()
        const x = this.x + Math.cos(angle) * distance
        const y = this.y + Math.sin(angle) * distance
        const sparkleSize = 0.5 + Math.random() * 0.5 // Smaller sparkles

        ctx.beginPath()
        ctx.arc(x, y, sparkleSize, 0, Math.PI * 2)
        ctx.fill()
      }
    }
  }

  applyPulseEffect(ctx: CanvasRenderingContext2D, color: string) {
    const size = this.size
    const pulseSize = size * (0.8 + 0.2 * Math.sin(this.frame / 3)) // Less pulsing

    // Very subtle expanding circle
    ctx.strokeStyle = color
    ctx.lineWidth = 0.3 // Thinner line
    ctx.globalAlpha = 0.08 + 0.05 * Math.cos(this.frame / 4) // Lower opacity

    ctx.beginPath()
    ctx.arc(this.x, this.y, pulseSize, 0, Math.PI * 2)
    ctx.stroke()

    // Optional center glow with low chance
    if (Math.random() > 0.7) {
      // Center glow
      const gradient = ctx.createRadialGradient(
        this.x,
        this.y,
        0,
        this.x,
        this.y,
        size * 0.2 // Smaller glow
      )

      gradient.addColorStop(0, color.replace(/[\d.]+\)$/, '0.1)')) // Lower opacity
      gradient.addColorStop(1, color.replace(/[\d.]+\)$/, '0)'))

      ctx.fillStyle = gradient
      ctx.beginPath()
      ctx.arc(this.x, this.y, size * 0.2, 0, Math.PI * 2)
      ctx.fill()
    }
  }

  applyBlurEffect(ctx: CanvasRenderingContext2D, color: string) {
    const size = this.size

    // Almost invisible cloud
    ctx.fillStyle = color.replace(/[\d.]+\)$/, '0.04)') // Lower opacity
    ctx.globalAlpha = 0.15 + 0.05 * Math.sin(this.frame / 5) // Less variation

    // Draw fewer fuzzy clouds
    const cloudCount = Math.random() > 0.5 ? 3 : 2 // 2-3 clouds only
    for (let i = 0; i < cloudCount; i++) {
      const angle = this.frame * 0.05 + i * Math.PI * 0.5 // Slower movement
      const distance = size * 0.15 // Closer together
      const x = this.x + Math.cos(angle) * distance
      const y = this.y + Math.sin(angle) * distance
      const cloudSize = size * 0.4 * (0.8 + 0.2 * Math.sin(i + this.frame / 6)) // Less variation

      ctx.beginPath()
      ctx.arc(x, y, cloudSize, 0, Math.PI * 2)
      ctx.fill()
    }
  }

  applyDistortionEffect(ctx: CanvasRenderingContext2D, color: string) {
    // Only show distortion sometimes
    if (Math.random() > 0.5) return

    const size = this.size

    // Visual representation of the distortion field
    ctx.strokeStyle = color.replace(/[\d.]+\)$/, '0.05)') // Lower opacity
    ctx.lineWidth = 0.3 // Thinner line

    // Simpler distortion field
    ctx.beginPath()
    const points = 6 // Fewer points
    for (let i = 0; i < points; i++) {
      const angle = (i / points) * Math.PI * 2
      const radius = size * (0.7 + 0.1 * Math.sin(this.frame / 3 + i)) // Less variation
      const x = this.x + Math.cos(angle) * radius
      const y = this.y + Math.sin(angle) * radius

      if (i === 0) {
        ctx.moveTo(x, y)
      } else {
        ctx.lineTo(x, y)
      }
    }
    ctx.closePath()
    ctx.stroke()

    // Central dot only sometimes
    if (Math.random() > 0.7) {
      ctx.fillStyle = color.replace(/[\d.]+\)$/, '0.1)') // Lower opacity
      ctx.beginPath()
      ctx.arc(this.x, this.y, 1, 0, Math.PI * 2) // Smaller dot
      ctx.fill()
    }
  }

  applyGlitchEffect(ctx: CanvasRenderingContext2D, color: string) {
    // Only show glitch effect very rarely
    if (Math.random() > 0.15 || this.frame % 4 !== 0) {
      return
    }

    const size = this.size * 0.4 // Smaller effect area

    // Random glitch squares and lines
    ctx.fillStyle = color.replace(/[\d.]+\)$/, '0.07)') // Lower opacity

    // Draw just 1 glitch rectangle most of the time
    const rectCount = Math.random() > 0.8 ? 2 : 1

    for (let i = 0; i < rectCount; i++) {
      const rectWidth = 2 + Math.random() * 5 // Smaller rectangles
      const rectHeight = 1 + Math.random() * 2
      const offsetX = (Math.random() - 0.5) * size
      const offsetY = (Math.random() - 0.5) * size

      ctx.fillRect(
        this.x + offsetX - rectWidth / 2,
        this.y + offsetY - rectHeight / 2,
        rectWidth,
        rectHeight
      )
    }

    // Occasional vertical line (rare)
    if (Math.random() > 0.85) {
      const lineHeight = 3 + Math.random() * 8 // Shorter line
      const offsetX = (Math.random() - 0.5) * size * 0.7

      ctx.fillRect(this.x + offsetX, this.y - lineHeight / 2, 0.5, lineHeight) // Thinner line
    }
  }

  // Check if the anomaly was clicked
  checkClick(x: number, y: number): boolean {
    if (this.stuckToCursor) return false

    const dx = x - this.x
    const dy = y - this.y
    const distanceSquared = dx * dx + dy * dy

    // Check if click is within the anomaly's clickable area (slightly larger than size)
    if (distanceSquared <= this.size * 1.5 * (this.size * 1.5)) {
      const now = performance.now()

      // Ensure clicks aren't too close together in time
      if (now - this.lastClickTime > this.clickTimeout) {
        this.clickCount++
        this.lastClickTime = now

        // After 3 clicks, stick to cursor
        if (this.clickCount >= 3) {
          this.stuckToCursor = true

          // Make anomaly more visible when stuck
          this.color = this.color.replace(/[\d.]+\)$/, '0.35)')
        } else {
          // Flee on first and second click
          this.fleeing = true
          setTimeout(() => (this.fleeing = false), 2000 + Math.random() * 1000)
        }

        return true
      }
    }

    return false
  }

  // New method for drawing April Fools hot dog
  drawHotDog(ctx: CanvasRenderingContext2D) {
    const size = this.size * 1.5

    // Hot dog bun (slightly yellow)
    ctx.fillStyle = 'rgba(240, 220, 160, 0.8)'
    const bunWidth = size * 2.2
    const bunHeight = size * 0.9

    // Draw rounded bun
    ctx.beginPath()
    ctx.ellipse(this.x, this.y, bunWidth / 2, bunHeight / 2, 0, 0, Math.PI * 2)
    ctx.fill()

    // Hot dog sausage (pinkish/red)
    ctx.fillStyle = 'rgba(220, 100, 80, 0.9)'
    const sausageWidth = size * 2
    const sausageHeight = size * 0.6

    ctx.beginPath()
    ctx.ellipse(
      this.x,
      this.y,
      sausageWidth / 2,
      sausageHeight / 2,
      0,
      0,
      Math.PI * 2
    )
    ctx.fill()

    // Ketchup & mustard
    if (Math.random() > 0.5) {
      ctx.strokeStyle = 'rgba(255, 30, 30, 0.8)' // ketchup
    } else {
      ctx.strokeStyle = 'rgba(255, 220, 30, 0.8)' // mustard
    }

    ctx.lineWidth = size * 0.1
    ctx.beginPath()

    // Wavy condiment line
    const lineLength = size * 1.4
    const startX = this.x - lineLength / 2
    const segments = 8

    for (let i = 0; i <= segments; i++) {
      const x = startX + (i * lineLength) / segments
      const yOffset = Math.sin(i + this.frame * 0.2) * size * 0.12

      if (i === 0) {
        ctx.moveTo(x, this.y + yOffset)
      } else {
        ctx.lineTo(x, this.y + yOffset)
      }
    }

    ctx.stroke()

    // Eyes (only when stuck to cursor or fleeing)
    if (this.stuckToCursor || this.fleeing) {
      const eyeSize = size * 0.15
      const eyeX = size * 0.3

      ctx.fillStyle = 'rgba(255, 255, 255, 0.9)'
      ctx.beginPath()
      ctx.arc(this.x - eyeX, this.y - size * 0.1, eyeSize, 0, Math.PI * 2)
      ctx.arc(this.x + eyeX, this.y - size * 0.1, eyeSize, 0, Math.PI * 2)
      ctx.fill()

      ctx.fillStyle = 'rgba(0, 0, 0, 0.9)'
      ctx.beginPath()
      ctx.arc(this.x - eyeX, this.y - size * 0.1, eyeSize * 0.5, 0, Math.PI * 2)
      ctx.arc(this.x + eyeX, this.y - size * 0.1, eyeSize * 0.5, 0, Math.PI * 2)
      ctx.fill()
    }
  }
}

const AnomalyManager = {
  anomalies: [] as AnomalyEntity[],
  initialized: false,
  spawnTimer: 0,
  maxAnomalies: 1, // Only one ghost at a time (was 2)

  // Track if the manager should be enabled (only on home page)
  enabled: false,

  // Check if it's a special date for themed anomalies
  getSeasonalTheme(): { isOctober: boolean; isAprilFools: boolean } {
    const now = new Date()
    const month = now.getMonth() // 0-indexed, 9 = October, 3 = April
    const day = now.getDate()

    return {
      isOctober: month === 9,
      isAprilFools: month === 3 && day === 1
    }
  },

  // Add click handler for anomalies
  handleClick(x: number, y: number): boolean {
    if (!this.enabled) return false

    let wasClicked = false
    for (const anomaly of this.anomalies) {
      if (anomaly.checkClick(x, y)) {
        wasClicked = true
      }
    }
    return wasClicked
  },

  // Enable/disable the manager
  setEnabled(isEnabled: boolean) {
    this.enabled = isEnabled

    // If disabled, clear all anomalies
    if (!isEnabled) {
      this.anomalies = []
      this.spawnTimer = 0
    } else if (!this.initialized && isEnabled) {
      // If enabled and not initialized, initialize
      this.initialized = true
      this.spawnTimer = 0

      // Check for seasonal themes
      const { isOctober, isAprilFools } = this.getSeasonalTheme()

      // For October, set max ghosts to 3 for pixelated ghosts
      if (isOctober && !isAprilFools) {
        this.maxAnomalies = 3
      }
      // For April Fools, set max to 2 for hot dogs
      else if (isAprilFools) {
        this.maxAnomalies = 2
      }
      // Otherwise, just one ghost
      else {
        this.maxAnomalies = 1
      }
    }
  },

  initialize(viewportWidth: number, viewportHeight: number) {
    if (this.initialized) return

    this.initialized = true
    this.anomalies = []
    PerformanceMonitor.reset()

    // Create just one initial anomaly
    this.spawnAnomaly(viewportWidth, viewportHeight)

    console.log('Anomaly Manager initialized')
  },

  spawnAnomaly(viewportWidth: number, viewportHeight: number) {
    if (this.anomalies.length >= this.maxAnomalies) return

    // Check for seasonal themes
    const { isOctober, isAprilFools } = this.getSeasonalTheme()

    // Randomly select an anomaly type based on season
    const anomalyTypes = Object.values(AnomalyType)
    let randomType

    if (isAprilFools) {
      // Hot dog special for April Fools!
      randomType = AnomalyType.GLITCH // We'll modify this to look like a hot dog
    } else if (isOctober) {
      // Prefer spookier types in October
      const spookyTypes = [AnomalyType.PHANTOM, AnomalyType.SHADOW]
      randomType = spookyTypes[Math.floor(Math.random() * spookyTypes.length)]
    } else {
      // Normal random selection
      randomType = anomalyTypes[Math.floor(Math.random() * anomalyTypes.length)]
    }

    // Create anomaly at random position
    const anomaly = new AnomalyEntity(
      Math.random() * viewportWidth,
      Math.random() * viewportHeight,
      randomType as AnomalyType,
      viewportWidth,
      viewportHeight
    )

    // Apply seasonal modifications
    if (isOctober && !isAprilFools) {
      // Make it pixelated for October
      anomaly.isPixelated = true
    } else if (isAprilFools) {
      // Make it a hot dog for April Fools
      anomaly.isHotDog = true
    }

    this.anomalies.push(anomaly)
  },

  update(
    mousePos: { x: number; y: number } | null,
    viewportWidth: number,
    viewportHeight: number
  ) {
    // Only update if enabled
    if (!this.enabled) return

    // Start performance timing
    PerformanceMonitor.startFrame()

    // Update high activity areas based on network data
    NetworkDataStream.updateHighActivityAreas()

    // Spawn a new anomaly much less frequently
    this.spawnTimer++
    if (this.spawnTimer > 1200 && this.anomalies.length < this.maxAnomalies) {
      // Every ~20 seconds (1200 instead of 600)
      this.spawnAnomaly(viewportWidth, viewportHeight)
      this.spawnTimer = 0
    }

    // Update all anomalies
    this.anomalies.forEach((anomaly) => {
      anomaly.update(mousePos, viewportWidth, viewportHeight)
    })

    // End performance timing
    PerformanceMonitor.endFrame()
  },

  // Draw visual effects for the anomalies
  drawEffects(ctx: CanvasRenderingContext2D, isDarkMode: boolean) {
    this.anomalies.forEach((anomaly) => {
      anomaly.applyEffectToContext(ctx, isDarkMode)
    })
  },

  // Check if a node is affected by any anomaly
  getNodeEffect(
    nodeX: number,
    nodeY: number,
    nodeIndex: number
  ): {
    color?: string
    sizeMultiplier?: number
    velocityChange?: { x: number; y: number }
  } | null {
    let strongestInfluence = 0
    let influencingAnomaly: AnomalyEntity | null = null

    // Find the anomaly with the strongest influence
    for (const anomaly of this.anomalies) {
      const influence = anomaly.affectsNode(nodeX, nodeY, nodeIndex)
      if (influence > strongestInfluence) {
        strongestInfluence = influence
        influencingAnomaly = anomaly
      }
    }

    // Higher threshold for effect visibility (0.15 instead of 0.1)
    if (strongestInfluence < 0.15 || !influencingAnomaly) {
      return null
    }

    // Variables used in case blocks
    const angle = Math.random() * Math.PI * 2

    // Apply effect based on anomaly type
    switch (influencingAnomaly.type) {
      case AnomalyType.SHIMMER:
        return {
          color: `rgba(180, 200, 255, ${0.3 * strongestInfluence})`, // Lower opacity
          sizeMultiplier: 1 + 0.3 * strongestInfluence // Smaller size change
        }
      case AnomalyType.PULSE:
        return {
          sizeMultiplier:
            1 +
            0.4 * strongestInfluence * Math.sin(influencingAnomaly.frame / 4) // Gentler pulse
        }
      case AnomalyType.BLUR:
        return {
          color: `rgba(124, 173, 255, ${0.2 * strongestInfluence})`, // Lower opacity
          sizeMultiplier: 1 + 0.2 * strongestInfluence // Smaller size change
        }
      case AnomalyType.DISTORTION:
        // Add small random movement to affected nodes
        return {
          velocityChange: {
            x: Math.cos(angle) * 0.1 * strongestInfluence, // Reduced movement
            y: Math.sin(angle) * 0.1 * strongestInfluence
          }
        }
      case AnomalyType.GLITCH:
        // Randomly makes nodes disappear and reappear
        if (Math.random() > 0.85) {
          // Less frequent disappearing
          return {
            sizeMultiplier: 0.3 // Reduce instead of hide completely
          }
        }
        return {
          color: `rgba(220, 220, 255, ${0.3 * strongestInfluence})`, // Lower opacity
          sizeMultiplier: 1 + 0.5 * Math.random() * strongestInfluence // Smaller size change
        }
      default:
        return null
    }
  },

  // Check if a line is affected by any anomaly
  getLineEffect(
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ): {
    color?: string
    width?: number
    dash?: number[]
  } | null {
    let strongestInfluence = 0
    let influencingAnomaly: AnomalyEntity | null = null

    // Find the anomaly with the strongest influence
    for (const anomaly of this.anomalies) {
      const influence = anomaly.affectsLine(x1, y1, x2, y2)
      if (influence > strongestInfluence) {
        strongestInfluence = influence
        influencingAnomaly = anomaly
      }
    }

    // Higher threshold for line effects (0.15 instead of 0.1)
    if (strongestInfluence < 0.15 || !influencingAnomaly) {
      return null
    }

    // Apply effect based on anomaly type
    switch (influencingAnomaly.type) {
      case AnomalyType.SHIMMER:
        return {
          color: `rgba(180, 200, 255, ${0.3 * strongestInfluence})` // Lower opacity
        }
      case AnomalyType.PULSE:
        return {
          width: 1 + strongestInfluence * 0.3 // Smaller width change
        }
      case AnomalyType.BLUR:
        // Make lines more transparent
        return {
          color: `rgba(124, 173, 255, ${0.2 * strongestInfluence})` // Lower opacity
        }
      case AnomalyType.DISTORTION:
        // Make lines wavy with longer dashes (less distortion)
        return {
          dash: [3, 3]
        }
      case AnomalyType.GLITCH:
        // Much less frequently break lines
        if (Math.random() > 0.85) {
          return {
            dash: [1, 7] // Bigger gaps
          }
        }
        return {
          color: `rgba(220, 220, 255, ${0.25 * strongestInfluence})` // Lower opacity
        }
      default:
        return null
    }
  },

  reset() {
    this.anomalies = []
    this.initialized = false
    this.spawnTimer = 0
    this.enabled = false
    NetworkDataStream.clear()
    PerformanceMonitor.reset()
  }
}

const Ghost: React.FC<GhostProps> = ({
  viewportWidth,
  viewportHeight,
  isHomePage = false
}) => {
  const isSetup = useRef(false)

  // Set up click handler for anomalies
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      AnomalyManager.handleClick(e.clientX, e.clientY)
    }

    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  // Initialize anomaly system and control by page
  useEffect(() => {
    // Enable only on home page
    AnomalyManager.setEnabled(isHomePage)

    if (isHomePage && !isSetup.current) {
      AnomalyManager.initialize(viewportWidth, viewportHeight)
      isSetup.current = true
    }

    // Cleanup
    return () => {
      if (!isHomePage) {
        AnomalyManager.reset()
        isSetup.current = false
      }
    }
  }, [viewportWidth, viewportHeight, isHomePage])

  // This is a controller component, not a visual one
  return null
}

// Export both the component and the manager for use in LineArtBackground
export { Ghost, AnomalyManager, NetworkDataStream }
export default Ghost
