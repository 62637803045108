import React from 'react'

interface SimpleCardProps {
  children: React.ReactNode
  className?: string
}

export const SimpleCard: React.FC<SimpleCardProps> = ({
  children,
  className = ''
}) => {
  return (
    <div
      className={`bg-white/60 dark:bg-gray-800/60 rounded-lg shadow-md p-6 backdrop-blur-sm overflow-hidden ${className}`}
    >
      {children}
    </div>
  )
}
