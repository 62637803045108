import React, { useRef, useEffect, useState } from 'react'

interface ObjectViewerProps {
  width?: string
  height?: string
  modelColor?: string
  backgroundColor?: string
  className?: string
}

const ObjectViewer: React.FC<ObjectViewerProps> = ({
  width = '100%',
  height = '400px',
  modelColor = '#2563eb', // Blue
  backgroundColor = '#f8fafc', // Light gray
  className = ''
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [rotation, setRotation] = useState({ x: 0, y: 0 })
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 })

  // Set up canvas and handle mouse events for rotation
  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    // Adjust for device pixel ratio
    const dpr = window.devicePixelRatio || 1
    canvas.width = canvas.clientWidth * dpr
    canvas.height = canvas.clientHeight * dpr
    ctx.scale(dpr, dpr)

    // Animation frame
    let animationFrameId: number

    // Draw the 3D object (a cube as a placeholder)
    const drawCube = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Set background
      ctx.fillStyle = backgroundColor
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Center point of canvas
      const centerX = canvas.width / (2 * dpr)
      const centerY = canvas.height / (2 * dpr)
      const size = Math.min(canvas.width, canvas.height) / (3 * dpr)

      // Draw a rotating cube (simplified)
      const rotationSpeed = 0.01

      // Auto-rotation when not interacting
      if (!isMouseDown) {
        setRotation((prev) => ({
          x: prev.x + rotationSpeed,
          y: prev.y + rotationSpeed * 0.7
        }))
      }

      // Simple cube wireframe
      ctx.strokeStyle = modelColor
      ctx.lineWidth = 2

      // Draw cube faces using rotation
      const sinX = Math.sin(rotation.x)
      const cosX = Math.cos(rotation.x)
      const sinY = Math.sin(rotation.y)
      const cosY = Math.cos(rotation.y)

      // Vertex points of a cube
      const vertices = [
        { x: -size, y: -size, z: -size },
        { x: size, y: -size, z: -size },
        { x: size, y: size, z: -size },
        { x: -size, y: size, z: -size },
        { x: -size, y: -size, z: size },
        { x: size, y: -size, z: size },
        { x: size, y: size, z: size },
        { x: -size, y: size, z: size }
      ]

      // Rotate and project vertices
      const projectedVertices = vertices.map((vertex) => {
        // Rotate around Y axis
        const rotatedY = {
          x: vertex.x * cosY - vertex.z * sinY,
          y: vertex.y,
          z: vertex.x * sinY + vertex.z * cosY
        }

        // Rotate around X axis
        const rotatedXY = {
          x: rotatedY.x,
          y: rotatedY.y * cosX - rotatedY.z * sinX,
          z: rotatedY.y * sinX + rotatedY.z * cosX
        }

        // Simple projection
        const scale = 400 / (400 + rotatedXY.z)
        return {
          x: centerX + rotatedXY.x * scale,
          y: centerY + rotatedXY.y * scale
        }
      })

      // Draw edges
      const edges = [
        [0, 1],
        [1, 2],
        [2, 3],
        [3, 0],
        [4, 5],
        [5, 6],
        [6, 7],
        [7, 4],
        [0, 4],
        [1, 5],
        [2, 6],
        [3, 7]
      ]

      ctx.beginPath()
      edges.forEach(([start, end]) => {
        ctx.moveTo(projectedVertices[start].x, projectedVertices[start].y)
        ctx.lineTo(projectedVertices[end].x, projectedVertices[end].y)
      })
      ctx.stroke()

      // Call next frame
      animationFrameId = requestAnimationFrame(drawCube)
    }

    drawCube()

    // Mouse event handlers for rotation
    const handleMouseDown = (e: MouseEvent) => {
      setIsMouseDown(true)
      setLastMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (!isMouseDown) return

      const deltaX = e.clientX - lastMousePosition.x
      const deltaY = e.clientY - lastMousePosition.y

      setRotation((prev) => ({
        x: prev.x + deltaY * 0.005,
        y: prev.y + deltaX * 0.005
      }))

      setLastMousePosition({
        x: e.clientX,
        y: e.clientY
      })
    }

    const handleMouseUp = () => {
      setIsMouseDown(false)
    }

    // Add event listeners
    canvas.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)

    // Clean up
    return () => {
      cancelAnimationFrame(animationFrameId)
      canvas.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isMouseDown, rotation, modelColor, backgroundColor])

  return (
    <div className={`relative ${className}`} style={{ width, height }}>
      <canvas
        ref={canvasRef}
        className='w-full h-full rounded-lg cursor-grab'
        style={{ touchAction: 'none' }}
      />
      <div className='absolute bottom-2 right-2 text-xs text-gray-500'>
        Drag to rotate
      </div>
    </div>
  )
}

export default ObjectViewer
