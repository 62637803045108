import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTheme } from '../../contexts/ThemeContext'
import LineArtBackground from '../experimental/line-art-background/LineArtBackground'
import { AnomalyManager } from '../experimental/ghost/Ghost'

/**
 * FULL VIEWPORT BACKGROUND SYSTEM
 * ===============================
 * This component establishes the full viewport animation backdrop and content container.
 *
 * RELATED COMPONENTS:
 * - LineArtBackground (provides the animation)
 * - Ghost (provides subtle anomaly effects)
 * - ThemedApp (places this as the root container)
 *
 * VIEWPORT COVERAGE:
 * The animation is designed to cover the entire viewport using:
 * 1. Fixed positioning (fixed inset-0) for the animation container
 * 2. w-screen/h-screen classes to ensure full width/height coverage
 * 3. overflow-hidden to prevent scrollbars on the animation only
 * 4. bottom-0 explicitly set to ensure coverage to viewport bottom
 *
 * Z-INDEX LAYERING:
 * - Animation container: z-0 (background)
 * - Content container: z-10 (foreground)
 *
 * MOBILE SCROLLING:
 * - The content container does NOT have overflow restrictions
 * - This ensures mobile scrolling works properly
 */

interface GlobalBackgroundProps {
  children: React.ReactNode
}

const GlobalBackground: React.FC<GlobalBackgroundProps> = ({ children }) => {
  // Track loaded state to properly handle animations
  const [isLoaded, setIsLoaded] = useState(false)
  const { theme } = useTheme()
  const location = useLocation()
  const pathname = location.pathname

  // Determine if we're on the home page
  const isHomePage = pathname === '/' || pathname === '/home'

  // Load handler to track when component is ready
  useEffect(() => {
    // Initially not loaded, set a timeout to trigger state change
    const timer = setTimeout(() => {
      setIsLoaded(true)
    }, 200)

    // Clear timeout if component unmounts
    return () => {
      clearTimeout(timer)
    }
  }, [])

  // Handle path changes
  useEffect(() => {
    console.log('Route changed to:', pathname)
  }, [theme, pathname])

  // Effect to handle theme changes for anomalies
  useEffect(() => {
    // Notify console about theme changes
    console.log('Theme changed to:', theme)

    // Reset anomalies when toggling between light/dark mode
    if (AnomalyManager.initialized) {
      // We don't need to fully reset, just spawn a new anomaly
      // with the updated theme when there's a change
      const viewport = {
        width: window.innerWidth,
        height: window.innerHeight
      }

      setTimeout(() => {
        if (AnomalyManager.anomalies.length < AnomalyManager.maxAnomalies) {
          AnomalyManager.spawnAnomaly(viewport.width, viewport.height)
        }
      }, 500)
    }
  }, [theme])

  // Background color fallback for when animation is not shown
  const bgColorClass = theme === 'dark' ? 'bg-gray-900' : 'bg-white'

  return (
    <div className={`relative w-full min-h-screen ${bgColorClass}`}>
      {/* Line art background with full viewport coverage */}
      <div
        className={`fixed inset-0 z-0 w-screen h-screen overflow-hidden transition-opacity duration-1000 ${
          isLoaded ? 'opacity-100' : 'opacity-0'
        }`}
        data-testid='background-container'
      >
        <React.Suspense
          fallback={<div className='w-full h-full bg-gray-900'></div>}
        >
          <LineArtBackground isHomePage={isHomePage} />
        </React.Suspense>
      </div>

      {/* Main content container */}
      <div
        className={`relative z-10 min-h-screen w-full transition-opacity duration-500 ${
          isLoaded ? 'opacity-100' : 'opacity-0'
        }`}
        data-testid='content-container'
      >
        {children}
      </div>
    </div>
  )
}

export default GlobalBackground
