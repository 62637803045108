/**
 * Safely check localStorage for a value, handling cases where localStorage isn't available
 */
const safeGetLocalStorage = (key: string): string | null => {
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      return localStorage.getItem(key)
    }
    return null
  } catch (e) {
    console.warn('Failed to access localStorage:', e)
    return null
  }
}

/**
 * Normalizes an image path to ensure it points to the correct location.
 * Handles both local paths and S3 bucket/CloudFront paths for blog images.
 */
export const normalizeImagePath = (path: string): string => {
  if (!path) return ''

  console.log('Normalizing image path:', path)

  // Check if path is already a fully qualified URL
  if (path.startsWith('http://') || path.startsWith('https://')) {
    return path
  }

  // CloudFront or S3 bucket URL configuration
  const CDN_URL =
    process.env.VITE_CDN_URL || 'https://d309xicbd1a46e.cloudfront.net'

  // Always use CDN/S3 in production, option to use local in development
  const isProd =
    process.env.NODE_ENV === 'production' ||
    safeGetLocalStorage('useCloudImages') === 'true'

  console.log('Environment:', process.env.NODE_ENV, 'Using CDN:', isProd)

  // If the path points to a blog image
  if (path.includes('/blog/') || path.includes('/images/blog/')) {
    // Extract the filename and determine subfolder
    const filename = path.split('/').pop() || ''

    // Parse the path to determine proper subfolder
    let subfolder = 'featured'

    if (path.includes('/thumbnails/')) {
      subfolder = 'thumbnails'
    } else if (path.includes('/optimized/')) {
      subfolder = 'optimized'
    } else if (path.includes('/original/')) {
      subfolder = 'original'
    } else if (path.includes('/featured/')) {
      subfolder = 'featured'
    }

    // For production or when explicitly using cloud images, use CDN
    if (isProd) {
      const result = `${CDN_URL}/${subfolder}/${filename}`
      console.log('Using CDN path:', result)
      return result
    }

    // For development, use local paths but ensure correct format
    // Make sure path is properly formatted with /images/blog/subfolder
    if (!path.includes(`/images/blog/${subfolder}/`)) {
      const result = `/images/blog/${subfolder}/${filename}`
      console.log('Using local path:', result)
      return result
    }

    // Path is already correctly formatted
    console.log('Path already formatted correctly:', path)
    return path
  }

  // For local paths that don't match the blog patterns
  console.log('Using original path:', path)
  return path
}

/**
 * Handles image loading errors by trying multiple fallback sources
 * @param event - The error event from the image
 * @param retries - Number of times to retry alternative sources
 * @returns void
 */
export const handleImageError = (
  event: React.SyntheticEvent<HTMLImageElement, Event>,
  retries = 2
): void => {
  const img = event.currentTarget
  const originalSrc = img.getAttribute('data-original-src') || img.src

  console.error(`Image load error for: ${img.src}`)

  // If this is the first error, store the original source
  if (!img.hasAttribute('data-original-src')) {
    img.setAttribute('data-original-src', originalSrc)
    img.setAttribute('data-retries', retries.toString())
    console.log('First error. Original src:', originalSrc, 'Retries:', retries)
  }

  const retriesLeft = Number(img.getAttribute('data-retries') || '0')

  if (retriesLeft <= 0) {
    // No more retries, use placeholder
    console.error(`Failed to load image after all retries: ${originalSrc}`)
    img.src = 'https://placehold.co/600x400?text=Image+Not+Found'
    img.removeAttribute('data-retries')
    return
  }

  // Track remaining retries
  img.setAttribute('data-retries', (retriesLeft - 1).toString())
  console.log(`Retries left: ${retriesLeft - 1}`)

  // Get current source to determine next fallback
  const currentSrc = img.src

  if (currentSrc.includes('cloudfront.net')) {
    // Try S3 bucket direct URL
    const filename = currentSrc.split('/').pop() || ''
    const subfolder = currentSrc.split('/').slice(-2, -1)[0] || 'featured'
    img.src = `https://jeffknowlesjr-assets.s3.amazonaws.com/images/blog/${subfolder}/${filename}`
    console.log(`Trying S3 fallback: ${img.src}`)
  } else if (currentSrc.includes('s3.amazonaws.com')) {
    // Try local path
    const filename = currentSrc.split('/').pop() || ''
    const subfolder = currentSrc.split('/').slice(-2, -1)[0] || 'featured'
    img.src = `/images/blog/${subfolder}/${filename}`
    console.log(`Trying local fallback: ${img.src}`)
  } else {
    // Fallback to placeholder
    console.log('All fallbacks failed, using placeholder')
    img.src = 'https://placehold.co/600x400?text=Image+Not+Found'
    img.removeAttribute('data-retries')
  }
}
