import React, { useRef, useEffect, useState } from 'react'

interface FluidBackgroundProps {
  width?: string
  height?: string
  colorA?: string
  colorB?: string
  speed?: number
  className?: string
}

const FluidBackground: React.FC<FluidBackgroundProps> = ({
  width = '100%',
  height = '100%',
  colorA = '#4338ca', // Indigo
  colorB = '#3b82f6', // Blue
  speed = 0.008,
  className = ''
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 })
  const [isPointerDown, setIsPointerDown] = useState(false)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    // Set canvas dimensions
    const dpr = window.devicePixelRatio || 1
    const updateSize = () => {
      canvas.width = canvas.clientWidth * dpr
      canvas.height = canvas.clientHeight * dpr
      ctx.scale(dpr, dpr)
    }

    updateSize()
    window.addEventListener('resize', updateSize)

    // Animation parameters
    let time = 0
    const numBlobs = 6
    const blobs: {
      x: number
      y: number
      angle: number
      speed: number
      radius: number
    }[] = []

    // Initialize blobs
    for (let i = 0; i < numBlobs; i++) {
      blobs.push({
        x: (Math.random() * canvas.width) / dpr,
        y: (Math.random() * canvas.height) / dpr,
        angle: Math.random() * Math.PI * 2,
        speed: 0.5 + Math.random() * 2,
        radius: 50 + Math.random() * 100
      })
    }

    // Create gradient
    let gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height)
    gradient.addColorStop(0, colorA)
    gradient.addColorStop(1, colorB)

    // Animation loop
    const animate = () => {
      // Update time
      time += speed

      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Update and draw blobs
      ctx.fillStyle = gradient
      ctx.globalAlpha = 0.8

      // Draw base background
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Update blobs
      blobs.forEach((blob, i) => {
        // Move in current angle
        blob.x += Math.cos(blob.angle) * blob.speed
        blob.y += Math.sin(blob.angle) * blob.speed

        // Change angle slightly for organic movement
        blob.angle += Math.sin(time * 0.1) * 0.05

        // Bounce off edges
        if (blob.x < 0 || blob.x > canvas.width / dpr) {
          blob.angle = Math.PI - blob.angle
        }
        if (blob.y < 0 || blob.y > canvas.height / dpr) {
          blob.angle = -blob.angle
        }

        // Draw blob
        ctx.beginPath()

        // Use bezier curves for organic shape
        const wobble = 0.5 + Math.sin(time * 0.5 + i) * 0.2
        const r = blob.radius * wobble

        // Create distorted circle using bezier curves
        for (let j = 0; j < 8; j++) {
          const angle = (j * Math.PI) / 4
          const nextAngle = ((j + 1) * Math.PI) / 4

          const startX = blob.x + Math.cos(angle + time * 0.3) * r
          const startY = blob.y + Math.sin(angle + time * 0.3) * r

          const endX = blob.x + Math.cos(nextAngle + time * 0.3) * r
          const endY = blob.y + Math.sin(nextAngle + time * 0.3) * r

          const cpX =
            blob.x + Math.cos((angle + nextAngle) / 2 + time * 0.3) * r * 1.5
          const cpY =
            blob.y + Math.sin((angle + nextAngle) / 2 + time * 0.3) * r * 1.5

          if (j === 0) {
            ctx.moveTo(startX, startY)
          }

          ctx.quadraticCurveTo(cpX, cpY, endX, endY)
        }

        ctx.closePath()
        ctx.fillStyle = i % 2 === 0 ? colorA : colorB
        ctx.globalAlpha = 0.3
        ctx.fill()
      })

      // Add interaction with pointer
      if (isPointerDown) {
        const { x, y } = pointerPosition

        // Create ripple effect
        ctx.beginPath()
        const rippleSize = 100 + Math.sin(time * 5) * 20
        ctx.arc(x, y, rippleSize, 0, Math.PI * 2)
        ctx.fillStyle = 'rgba(255, 255, 255, 0.2)'
        ctx.fill()

        // Add blob at pointer
        ctx.beginPath()
        ctx.arc(x, y, 30, 0, Math.PI * 2)
        ctx.fillStyle = 'rgba(255, 255, 255, 0.4)'
        ctx.fill()
      }

      requestAnimationFrame(animate)
    }

    const animationId = requestAnimationFrame(animate)

    // Handle pointer events
    const handlePointerMove = (e: PointerEvent) => {
      const rect = canvas.getBoundingClientRect()
      setPointerPosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      })
    }

    const handlePointerDown = () => {
      setIsPointerDown(true)
    }

    const handlePointerUp = () => {
      setIsPointerDown(false)
    }

    canvas.addEventListener('pointermove', handlePointerMove)
    canvas.addEventListener('pointerdown', handlePointerDown)
    window.addEventListener('pointerup', handlePointerUp)

    return () => {
      cancelAnimationFrame(animationId)
      window.removeEventListener('resize', updateSize)
      canvas.removeEventListener('pointermove', handlePointerMove)
      canvas.removeEventListener('pointerdown', handlePointerDown)
      window.removeEventListener('pointerup', handlePointerUp)
    }
  }, [colorA, colorB, speed])

  return (
    <div
      className={`${className}`}
      style={{ width, height, overflow: 'hidden' }}
    >
      <canvas ref={canvasRef} className='w-full h-full' />
    </div>
  )
}

export default FluidBackground
