import React from 'react'
import { Link } from 'react-router-dom'
import { SimpleCard } from '../common/SimpleCard'
import { BlogPost } from '../../types/blog'
import { normalizeImagePath } from '../../utils/imageUtils'

/**
 * BLOG POST LIST COMPONENT
 * =======================
 * Displays a grid of blog post cards with consistent padding.
 *
 * RELATED COMPONENTS:
 * - BlogLayout (parent container that provides standard layout)
 * - SimpleCard (used for each blog post card)
 * - Blog page (parent page that renders this component)
 *
 * PADDING REQUIREMENTS:
 * This component should NOT add its own horizontal padding as it's provided by:
 * - BlogLayout provides px-4 standard content padding
 * - The parent Blog page wraps this component
 */

interface BlogPostListProps {
  posts: BlogPost[]
}

// Debug mode - set to false to disable logging
const DEBUG = false

const debug = {
  log: (...args: unknown[]) => {
    if (DEBUG) {
      console.log('[BlogPostList]', ...args)
    }
  }
}

export const BlogPostList: React.FC<BlogPostListProps> = ({ posts }) => {
  debug.log('Rendering with posts:', posts.length)

  if (!posts || posts.length === 0) {
    debug.log('No posts available')
    return <div>No blog posts found.</div>
  }

  return (
    <div
      className='grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full pb-6 overflow-hidden'
      data-role='blog-post-list'
    >
      {posts.map((post) => {
        const imageUrl = post.featuredImage
          ? normalizeImagePath(post.featuredImage)
          : 'https://placehold.co/800x450?text=No+Image'

        return (
          <Link key={post.slug} to={`/blog/${post.slug}`} className='h-full'>
            <div className='h-full transition-transform hover:-translate-y-1 duration-200'>
              <SimpleCard className='h-full overflow-hidden'>
                <article className='flex flex-col h-full'>
                  <div className='relative pb-[56.25%] mb-5 rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-800'>
                    <img
                      src={imageUrl}
                      alt={post.title}
                      className='absolute inset-0 w-full h-full object-cover'
                      onError={(e) => {
                        // Fall back to placeholder if image fails to load
                        const target = e.target as HTMLImageElement
                        debug.log(`Image failed to load: ${target.src}`)
                        target.src =
                          'https://placehold.co/800x450?text=No+Image'
                        target.onerror = null // Prevent infinite error loop
                      }}
                    />
                  </div>
                  <div className='flex flex-col flex-1'>
                    <h2 className='text-lg sm:text-xl font-bold mb-2 text-gray-900 dark:text-white line-clamp-2'>
                      {post.title}
                    </h2>
                    <p className='text-sm text-gray-600 dark:text-gray-300 mb-3 line-clamp-3 flex-1'>
                      {post.excerpt}
                    </p>
                    <div className='mt-auto space-y-2'>
                      <div className='flex flex-wrap items-center text-xs text-gray-500 dark:text-gray-400 gap-1'>
                        <span className='font-medium'>{post.author}</span>
                        <span>•</span>
                        <span>
                          {new Date(post.publishedAt).toLocaleDateString()}
                        </span>
                        <span>•</span>
                        <span>{post.readingTime} min read</span>
                      </div>
                      <div className='flex flex-wrap gap-1'>
                        {post.tags.slice(0, 3).map((tag) => (
                          <span
                            key={tag}
                            className='px-2 py-0.5 text-xs bg-gray-100/60 dark:bg-gray-700/60 rounded-full text-gray-600 dark:text-gray-300'
                          >
                            {tag}
                          </span>
                        ))}
                        {post.tags.length > 3 && (
                          <span className='px-2 py-0.5 text-xs bg-gray-100/60 dark:bg-gray-700/60 rounded-full text-gray-600 dark:text-gray-300'>
                            +{post.tags.length - 3} more
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </article>
              </SimpleCard>
            </div>
          </Link>
        )
      })}
    </div>
  )
}
